import React from 'react';

function preloadImages(imageUrls) {
  if (!Array.isArray(imageUrls) || imageUrls.length === 0) {
    return;
  }

  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
}

export default preloadImages;
