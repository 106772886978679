// importImages.js
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function importAll(r) {
  let images = {};
  r.keys().forEach((key) => {
    // Extract the image name without the .png extension
    const imageName = capitalizeFirstLetter(key.replace(/^\.\//, '').replace(/\.(png)$/, ''));
    images[imageName] = r(key);
  });
  return images;
}


const images = importAll(require.context('../baseimgs/', false, /\.(png)$/));

export default images;
