import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useUserAuth } from "./UserAuthContext";
import '../css/Header.css';

function Header() {
  const { user, updateTable } = useUserAuth();
  const [menuOpen, setMenuOpen] = useState(true);
  const location = useLocation();

  const navigate = useNavigate();

  const isCurrentPath = (path) => {
    if (path === "/")
      return location.pathname === path;
    else
      return location.pathname.startsWith(path);
  };

  const isHomepageActive = isCurrentPath("/");
  const isPlayActive = isCurrentPath("/Play");
  const isAffiliateActive = isCurrentPath("/Affiliate");
  const isAboutActive = isCurrentPath("/About");
  const isAuthActive = isCurrentPath("/Auth");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const resetLanguages = async () => {
    if (user) {
      try {
        await updateTable('profiles', { selectedLanguages: null}, { id: user.id });
        user.selectedLanguages = null;
      } catch (error) {
        alert(error.message);
        localStorage.removeItem('selectedLanguages');
      }
    } else {
      localStorage.removeItem('selectedLanguages');
    }
  };

  const resetLanguage = async (event) => {
    event.preventDefault();
    await resetLanguages();
    navigate('/');
  };

  const handleNavigation = (path) => {
    if (isCurrentPath(path)) {
      window.location.href = path;
    } else {
      navigate(path);
    }
  };

  return (
    <header className="header">
      <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <Link onClick={(e) => {e.preventDefault(); handleNavigation("/Play");}} className={`header-button ${isPlayActive ? 'highlight' : ''}`}>Start</Link>
        <span className="separator">|</span>
        <Link onClick={resetLanguage} to="/" className={`header-button ${isHomepageActive ? 'highlight' : ''}`}>Languages</Link>
        <span className="separator">|</span>
        <Link onClick={(e) => {e.preventDefault(); handleNavigation("/About");}} className={`header-button ${isAboutActive ? 'highlight' : ''}`}>Guide</Link>
        <span className="separator">|</span>
        {user ? (
          <Link to="/Logout" className={`header-button ${isAuthActive ? 'highlight' : ''}`}>
            {'Logout'}
          </Link>
        ) : (
          <Link onClick={(e) => {e.preventDefault(); handleNavigation("/Auth");}} className={`header-button ${isAuthActive ? 'highlight' : ''}`}>Login</Link>
        )}
      </nav>
      <a href="/"><span className="header-image">
        <img src="/logo512.png" alt="Visulang Logo" />
      </span></a>
    </header>
  );
}
export default Header;

