import React from 'react';

const playAudio = (audioFile) => {
  const audio = new Audio(audioFile);
  audio.play();
};

function OverlayText({position, index}) {
  return (
    <div
      key={index}
      className="overlay-text"
      style={{
        top: position.top,
        left: position.left,
        color: position.color,
      }}
      onClick={() => playAudio(position.audiofile)}
    >
      <span className="overlay-text-nopointer">
        <img
          key={index}
          src={position.flag}
          alt={`The word ${position.original} in ${position.language} is ${position.word}`}
          style={{
            position: 'relative',
            height: '18px',
            marginRight: '8%',
          }}
          />
        {position.word}
      </span>
    </div>
  );
}


export default OverlayText;
