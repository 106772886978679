function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function importAll(r) {
  let audioFiles = {};
  r.keys().forEach((key) => {
    const audioFileName = capitalizeFirstLetter(key.replace(/^\.\//, '').replace(/\.(mp3)$/, ''));
    audioFiles[audioFileName] = r(key);
  });
  return audioFiles;
}

const englishAudioFiles = importAll(
  require.context('../english-audio/', false, /\.(mp3)$/)
);

export default englishAudioFiles;
