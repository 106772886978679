import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "./components/UserAuthContext";


const Logout = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await logOut();
        navigate("/");
      } catch (error) {
        return;
      }
    };

    handleLogout(); // Call handleLogout function on component mount
  }, []); // Empty dependency array means this effect will only run once after the initial render

  return null; // Or you can return some JSX if needed
}

export default Logout;
