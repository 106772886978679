// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [authLoaded, setAuthLoaded] = useState(false);

  async function selectFromTable(tableName, columns = ['*'], filterConditions = {}, orderBy = '', order = 'asc', single = false) {
    let query = supabase
      .from(tableName)
      .select(columns.join(','))
      .match(filterConditions);

    if (orderBy) {
      query = query.order(orderBy, { ascending: order === 'asc' });
    }

    if (single) {
      query = query.single();
    }

    const { data, error } = await query;

    if (error) {
      throw new error;
    }

    return data;
  }

  async function insertIntoTable(tableName, data) {
    const { data: insertedData, error } = await supabase.from(tableName).insert(data);
    if (error) {
      throw error;
    }

    return insertedData;
  }

  async function updateTable(tableName, updateData, filterConditions) {
    const { data: updatedData, error } = await supabase
      .from(tableName)
      .update(updateData)
      .match(filterConditions);

    if (error) {
      throw error;
    }

    return updatedData;
  }

  async function deleteFromTable(tableName, filterConditions) {
    const { data, error } = await supabase
      .from(tableName)
      .delete()
      .match(filterConditions);

    if (error) {
      throw error;
    }

    return data;
  }

  async function logIn(email, password) {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;

    await updateUserFromProfile();
  }

  async function signUp(email, username, password) {
    const { data, error: authError } = await supabase.auth.signUp({ email, password });

    if (authError) {
      throw authError;
    }

    if (data.user) {
      const profileData = {
        id: data.user.id,
        username,
        paymentReceived: false,
        skippedPaid: false,
        selectedLanguages: localStorage.getItem('selectedLanguages') || null,
        lastWord: null,
      };

      try {
        await insertIntoTable('profiles', profileData);
        await updateUserFromProfile();
      } catch (profilesError) {
        await supabase.rpc('delete_user');
        await supabase.auth.signOut();
        throw profilesError;
      }
    }
  }

  async function logOut() {
    const { error } = await supabase.auth.signOut();
    localStorage.clear();
    if (error) throw error;
    setUser(null);
  }

  const sendPasswordReset = async (email) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) throw error;
  };

  const updateUserFromProfile = async () => {
    const data = await supabase.auth.getUser();

    if (!data || !data.data) {
      throw new Error("No data");
    }

    if (!data.data.user) {
      return;
    }

    try {
      const filterConditions = { id: data.data.user.id };
      const profile = await selectFromTable('profiles', ['*'], filterConditions, '', '', true);
      setUser({ ...data.data.user, ...profile });
    } catch (profileError) {
      throw profileError;
    }
  };

  useEffect(() => {
    const fetchInitialSession = async () => {
      try {
        await updateUserFromProfile();
      } catch (error) {
        alert(error.message);
        localStorage.clear();
        window.location.href = "/";
      }
      setAuthLoaded(true);
    };
    fetchInitialSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log(event);
      switch (event) {
        case 'SIGNED_OUT':
          setUser(null);
          localStorage.clear();
          window.location.href = "/";
          break;
        case 'INITIAL_SESSION':
        case 'TOKEN_REFRESHED':
        case 'USER_UPDATED':
//        case 'SIGNED_IN':
          try {
            await updateUserFromProfile();
         } catch (error) { alert(error.message); }
         break;
      }
    });

    return () => {
      authListener?.unsubscribe();
    };
  }, []);

  if (!authLoaded) {
    return "";
  }

  return (
    <userAuthContext.Provider
      value={{ updateUserFromProfile, user, authLoaded, selectFromTable, insertIntoTable, updateTable, logIn, signUp, logOut, sendPasswordReset }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
