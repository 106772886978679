import React, { useEffect, useState } from "react";

import './css/Play.css';

const Play = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [aboutPageText, setAboutPageText] = useState([]);

  const handleNext = () => {
    setImageIndex((prevIndex) => Math.min(prevIndex + 1, 4)); // Prevent going above 2
  };

  const handlePrevious = () => {
    setImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Add event listeners for keyboard events
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'ArrowLeft') {
        handlePrevious();
      }
    };

    // Attach the event listener to the window
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
  const aboutPageText = [`
    <p style="font-size: 24px;"><b>Part 1</b></p>
    <p style="font-size: 18px;">🌟 <b>Enhance Learning, Earn Together!</b> 🌟</p>
    <p style="font-size: 16px;">Join our Vislang Affiliate Program and boost language learning without competing with your current offerings.</p>
`,`
    <p style="font-size: 24px;"><b>Part 2</b></p>
    <p style="font-size: 18px;">🎉 <b>Value-Added Learning</b> 🎉</p>
    <p style="font-size: 16px;">Earn 25% referral fees while granting students a 25% discount. It's a win-win partnership that elevates your reputation.</p>
`,`
    <p style="font-size: 24px;"><b>Part 3</b></p>
    <p style="font-size: 18px;">✨ <b>Why Partner with Us?</b> ✨</p>
    <ul style="font-size: 16px;">
      <li>Expand your offering.</li>
      <li>Elevate your status.</li>
      <li>Help students excel.</li>
    </ul>
`,`
    <p style="font-size: 24px;"><b>Part 4</b></p>
    <p style="font-size: 18px;">🚀 <b>Join Us Today!</b> 🚀</p>
    <p style="font-size: 16px;">Ready to add value without competition? DM us now and let's empower language learners together.</p>
`,`
    <p style="font-size: 24px;"><b>Part 5</b></p>
    <p style="font-size: 18px;">🌎💬 <b>Spread the Word!</b> 🌎💬</p>
    <p style="font-size: 16px;">Share this post and help more people access quality language education. Together, we can make a difference!</p>
  `];
    const updateOverlayText = () => {
      setAboutPageText(aboutPageText[imageIndex]);
    };


    updateOverlayText();
  }, [imageIndex]);

  return (
   <div>
    <div className="play-form-container">
      <div className="play-form">
        <div className="play-form-content">
          <div id="mainFrame" className="main-frame">
            <div className="about-description" dangerouslySetInnerHTML={{ __html: aboutPageText }}></div>
          </div>
        </div>
      </div>
    </div>
    <div className="button-container">
      <div className="bottom-clickable-div left" onClick={handlePrevious}><div className="bottom-click-div left"></div></div>
      <div className="bottom-clickable-div right" onClick={handleNext}><div className="bottom-click-div right"></div></div>
    </div>
  </div>


  );
};

export default Play;

