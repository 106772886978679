import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "./components/UserAuthContext";
import './css/Auth.css';
import "bootstrap/dist/css/bootstrap.min.css";

const Auth = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [refCode, setRefCode] = useState("");
  const [donateURL, setDonateURL] = useState(null);
  const [fromPlay, setFromPlay] = useState(null);
  const { updateTable, signUp, logIn, user, sendPasswordReset } = useUserAuth();

  const [loginFailed, setLoginFailed] = useState(false);
  const [authMode, setAuthMode] = useState("signup");
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('donateURL') !== null) {
      setDonateURL(sessionStorage.getItem('donateURL'));
      sessionStorage.removeItem('donateURL');
    }

    if (sessionStorage.getItem('fromPlay') !== null) {
      const playCount = parseInt(sessionStorage.getItem('playCount')) || 0;
      const newPlayCount = playCount + 1;
      sessionStorage.setItem('playCount', newPlayCount);
      setFromPlay(sessionStorage.getItem('fromPlay'));
      sessionStorage.removeItem('fromPlay');
    }
    if (user) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (fromPlay == 'true') {
        try {
          updateTable('profiles', {skippedPaid: true}, {id: user.id});
          user.skippedPaid = true;
          navigate(donateURL);
        } catch (error) {
          alert(error.message);
          navigate("/");
        }
      } else {
        navigate('/');
      }
    }
  }, [user]);

  const changeAuthMode = (mode) => {
    setAuthMode(mode);
    setLoginFailed(false);
    setError("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setLoginFailed(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setLoginFailed(false);
  };

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
    setLoginFailed(false);
  };

  const handleRefCodeChange = (event) => {
    setRefCode(event.target.value);
    setLoginFailed(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (authMode === "signup") {
        await signUp(email, userName, password);
      } else if (authMode === "signin") {
        await logIn(email, password);
      } else if (authMode === "reset") {
        await sendPasswordReset(email);
        setError("Password reset email sent. Please check your inbox.");
      }
    } catch (err) {
      setError(err.message);
      setPassword("");
      setLoginFailed(true);
    }
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">
            {authMode === "signin" ? "Sign In" : authMode === "signup" ? "Sign Up" : "Reset Password"}
          </h3>
          {loginFailed && <p style={{ color: "red" }}>{error}</p>}
          {authMode !== "reset" && authMode === "signup" && (
            <div className="form-group mt-3">
              <label>Username</label>
              <input
                type="text"
                className="form-control mt-1"
                placeholder="e.g LanguageStarUser93"
                value={userName}
                onChange={handleUserNameChange}
              />
            </div>
          )}
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          {authMode !== "reset" && (
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
          )}
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              {authMode === "signin" ? "Sign In" : authMode === "signup" ? "Sign Up" : "Reset Password"}
            </button>
          </div>
          <div className="text-center mt-2">
            {authMode === "signin" ? (
              <>
                <div className="text-center mt-2">
                  Not registered yet?{" "}
                  <a href="javascript:;" onClick={() => changeAuthMode("signup")}>
                    Sign Up.
                  </a>
                </div>
                <div className="text-center mt-2">
                  Forgotten{" "}
                  <a href="javascript:;" onClick={() => changeAuthMode("reset")}>
                    password?
                  </a>
                </div>
              </>
            ) : authMode === "signup" ? (
              <div className="text-center mt-2">
                Already registered?{" "}
                <a href="javascript:;" onClick={() => changeAuthMode("signin")}>
                  Sign In
                </a>
              </div>
            ) : (
              <div>
                Remember your password?{" "}
                <a href="javascript:;" onClick={() => changeAuthMode("signin")}>
                  Sign In
                </a>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Auth;
