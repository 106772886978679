// importSubImages.js
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function importAll(r) {
  let images = {};
  r.keys().forEach((key) => {
    // Extract the image name without the .png extension
    const imageName = capitalizeFirstLetter(key.replace(/^\.\//, '').replace(/\.(png)$/, ''));
    images[imageName] = r(key);
  });
  return images;
}

const sub_images = importAll(
  require.context('../subimages/', false, /\.(png)$/)
);

const sub_images_audio = importAll(
  require.context('../subimages-audio/', false, /\.(mp3)$/)
);

function parseImageName(imageName) {
  const parts = imageName.split('_');
  if (parts.length === 3) {
    const [word, language, translation] = parts;

    return { language, word, translation, image: sub_images[imageName] };
  }
  return null; // Invalid format
}

function parseAudioName(audioName) {
  const parts = audioName.split('_');
  if (parts.length === 3) {
    const [word, language, translation] = parts;

    return { language, word, translation, audio: sub_images_audio[audioName] };
  }
  return null; // Invalid format
}


function createImageArray() {
  const imageArray = {};
  Object.keys(sub_images).forEach((imageName) => {
    const parsedImage = parseImageName(imageName);

    if (parsedImage) {
      const { language, word, translation, image } = parsedImage;

      if (!imageArray[language]) {
        imageArray[language] = {};
      }

      imageArray[language][word] = {};
      imageArray[language][word]['image'] = image;
      imageArray[language][word]['translation'] = translation;
    }
  });

  Object.keys(sub_images_audio).forEach((audioName) => {
    const parsedAudio = parseAudioName(audioName);

    if (parsedAudio) {
      const { language, word, translation, audio } = parsedAudio;

      if (imageArray[language] && imageArray[language][word]) {
        imageArray[language][word]['audio'] = audio;
      }
    }
  });

  return imageArray;
}

const subImages = createImageArray();

export default subImages;

