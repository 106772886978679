import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import './css/Play.css';
import images from './components/importImages';
import subImages from './components/importSubImages';
import flagMappings from './components/importFlags';
import englishAudioFiles from './components/importEnglishAudioFiles';
import OverlayImage from './components/OverlayImage';
import OverlayText from './components/OverlayText';
import subWordLocs from './constants/SubWordLocs';
import baseImageLocs from './constants/BaseImageLocs';
import { useUserAuth } from "./components/UserAuthContext";

const bail = () => {
  window.location.href = '/';
  return;
}

const playAudio = (audioFile) => {
  const audio = new Audio(audioFile);
  audio.play();
};

const Play = () => {
  const navigate = useNavigate();
  const { word, languages, translations } = useParams();

  const baseImagePaths = Object.values(images);
  const imageNames = Object.keys(images);

  const [firstLoad, setFirstLoad] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);
  const [overlayImagePositions, setOverlayImagePositions] = useState([]);
  const [overlayTextPositions, setOverlayTextPositions] = useState([]);
  const [loading, setLoading] = useState(true);

  const { authLoaded, user } = useUserAuth();

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundImageWord, setBackgroundImageWord] = useState(null);

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [playCount, setPlayCount] = useState(() => {
    return parseInt(sessionStorage.getItem('playCount')) || 0;
  });

  const handleNext = () => {
    setImageIndex((prevIndex) => {
      const nextIndex = Math.min(prevIndex + 1, Object.keys(images).length - 1);
      if (nextIndex !== prevIndex) {
        setLoading(true);
      }
      return nextIndex;
    });
  };

  const handlePrevious = () => {
    setImageIndex((prevIndex) => {
      const previousIndex = Math.max(prevIndex - 1, 0);
      if (previousIndex !== prevIndex) {
        setLoading(true);
      }
      return previousIndex;
    });
  };
  const checkSelectedLanguages = () => {
    let localSelectedLanguages = [];
    try {
      if (authLoaded) {
        if (!user) {
          localSelectedLanguages = JSON.parse(localStorage.getItem('selectedLanguages'));
        } else {
          localSelectedLanguages = JSON.parse(user.selectedLanguages);
        }
      } else {
        localSelectedLanguages = [];
      }
    } catch (error) {
      localSelectedLanguages = [];
    }

    return localSelectedLanguages === "[]" || localSelectedLanguages === null || localSelectedLanguages === "null" || localSelectedLanguages === '"null"' ? [] : localSelectedLanguages; // TODO: fix this..
  };

  const getSetIndexFromURL = () => {
    if (languages && translations && word) {
      const index = imageNames.indexOf(word);
      if (index === -1) { bail(); return; }

      const urlLanguages = languages.split('-');
      const urlTranslations = translations.split('-');

      if (urlLanguages.length < 1 || urlLanguages.length !== urlTranslations.length) { bail(); return; }

      for (let i = 0; i < urlLanguages.length; i++) {
        const language = urlLanguages[i];
        if (!(language in flagMappings)) { bail(); return; }
        if (subImages[language][word]['translation'] !== urlTranslations[i]) { bail(); return; }
      }
      setSelectedLanguages(urlLanguages);
      setImageIndex(index);

      return true;
    }

    return false;
  };

  useEffect(() => {
    const newPlayCount = playCount + 1;
    sessionStorage.setItem('playCount', newPlayCount);
    setPlayCount(newPlayCount);

    if(word) {
      if (imageIndex === 0 && word != imageNames[imageIndex] && !authLoaded) {
        return;
      }
    }

    setBackgroundImage(baseImagePaths[imageIndex]);
    setBackgroundImageWord(imageNames[imageIndex]);
  }, [imageIndex]);

  useEffect(() => {
    const translatedWords = selectedLanguages.map((language) => {
      if (
        subImages[language] &&
        subImages[language][backgroundImageWord] &&
        subImages[language][backgroundImageWord]['translation']
      ) {
        return subImages[language][backgroundImageWord]['translation'];
      } else {
        return 'null';
      }
    });

    const urlLanguage = selectedLanguages.join('-');
    const urlTranslations = translatedWords.join('-');
    const fullURL = urlTranslations && backgroundImageWord && urlLanguage;
    const targetURL = `/Play/${backgroundImageWord}/${urlLanguage}/${urlTranslations}`;

    if (!fullURL)
      return;

    if (imageIndex > 22 && authLoaded && !(user && user.paymentReceived)) {
      sessionStorage.setItem('donateURL', targetURL);
      sessionStorage.setItem('fromPlay', true);
      navigate('/Donate');
      return;
    }

    if (imageIndex > 9 && user && authLoaded && !user.paymentReceived && !user.skippedPaid && fullURL) {
      sessionStorage.setItem('donateURL', targetURL);
      sessionStorage.setItem('fromPlay', true);
      navigate('/Donate');
      return;
    }

    if (imageIndex > 9 && !user && fullURL && authLoaded) {
      sessionStorage.setItem('donateURL', targetURL);
      sessionStorage.setItem('fromPlay', true);
      navigate('/Donate');
      return;
    }

    let documentTitle = "Visulang: " + backgroundImageWord + " in ";
    if (selectedLanguages.length === 1) {
      documentTitle += selectedLanguages[0] + ".";
    } else if (selectedLanguages.length === 2) {
      documentTitle += selectedLanguages[0] + " and " + selectedLanguages[1] + ".";
    } else if (selectedLanguages.length > 2) {
      documentTitle += selectedLanguages.slice(0, -1).join(", ") + ", and " + selectedLanguages[selectedLanguages.length - 1] + ".";
    }

    if (document.title !== documentTitle && fullURL) {
      if (firstLoad) {
        navigate(targetURL, {replace: true});
        setFirstLoad(false);
      } else {
        navigate(targetURL);
      }
      document.title = documentTitle;
    }
  }, [backgroundImageWord, selectedLanguages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'ArrowLeft') {
        handlePrevious();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const updateSelectedLanguages = async () => {
      const languagesFromCheck = checkSelectedLanguages();

      if (languagesFromCheck.length < 1) {
        bail();
        return;
      }
      setSelectedLanguages(languagesFromCheck);
    };

    if (authLoaded && !getSetIndexFromURL()) updateSelectedLanguages();
  }, [authLoaded]);

  useEffect(() => {
    const updateOverlayImagePositions = () => {
      if (selectedLanguages.length > 0) {
        const newOverlayImagePositions = selectedLanguages.map((language, index) => {
          const subImage = subImages[language] && subImages[language][backgroundImageWord];
          if (subImage && baseImageLocs[backgroundImageWord]) {
            return {
              top: baseImageLocs[backgroundImageWord][index]['top'],
              left: baseImageLocs[backgroundImageWord][index]['left'],
              imageSrc: subImage['image'],
              height: '18%',
              word: subImage['translation'],
              language: language,
              original: backgroundImageWord,
            };
          } else {
            return {};
          }
        });
        setOverlayImagePositions(newOverlayImagePositions);
      } else {
        setOverlayImagePositions([]);
      }
    };

    const updateOverlayTextPositions = () => {
      if (selectedLanguages.length > 0) {
        const newOverlayTextPositions = selectedLanguages.map((language, index) => {
          const subImage = subImages[language] && subImages[language][backgroundImageWord];
          if (subImage && subWordLocs[backgroundImageWord]) {
            return {
              top: subWordLocs[backgroundImageWord][index]['top'],
              left: subWordLocs[backgroundImageWord][index]['left'],
              word: subImage['translation'],
              flag: flagMappings[language]['image'],
              color: flagMappings[language]['color'],
              language: language,
              original: backgroundImageWord,
              audiofile: subImages[language][backgroundImageWord]['audio'],
            };
          } else {
            return {};
          }
        });
        setOverlayTextPositions(newOverlayTextPositions);
      } else {
        setOverlayTextPositions([]);
      }
    };

    updateOverlayImagePositions();
    updateOverlayTextPositions();
  }, [backgroundImageWord, selectedLanguages]);

  useEffect(() => {
    const preloadFiveImages = () => {
      const totalImages = 5;

      for (let i = imageIndex; i < imageIndex + totalImages && i < baseImagePaths.length; i++) {
        const img = new Image();
        img.src = baseImagePaths[i];

        selectedLanguages.forEach((language) => {
          if (subImages[language] && subImages[language][imageNames[i]]) {
            const img_j = new Image();
            img_j.src = subImages[language][imageNames[i]]['image'];
          }
        });
      }
    };

    const loadCurrentImage = () => {
      let imagesToLoad = 1;

      if (selectedLanguages.length < 1) return;

      const checkLoading = () => {
        imagesToLoad--;
        if (imagesToLoad === 0) {
          setLoading(false);
        }
      };

      const img = new Image();
      img.src = baseImagePaths[imageIndex];
      img.onload = checkLoading;

      selectedLanguages.forEach((language) => {
        if (subImages[language] && subImages[language][imageNames[imageIndex]]) {
          imagesToLoad++;
          const img_j = new Image();
          img_j.src = subImages[language][imageNames[imageIndex]]['image'];
          img_j.onload = checkLoading;
        }
      });
    };

    loadCurrentImage();
    preloadFiveImages();
  }, [imageIndex, selectedLanguages]);

  return (
    <div>
      <div className="play-form-container">
        <div className="play-form">
          <div className="play-form-content">
            <div id="mainFrame" className="main-frame">
              {loading || ! backgroundImageWord ? null : (
                <>
                  <div id="mainSecondFrame" className="main-second-frame">
                    <div id="mainThirdFrame" className="main-third-frame" style={{ backgroundImage: `url(${backgroundImage})` }} />
                  </div>
                  <div onClick={() => playAudio(englishAudioFiles[backgroundImageWord])} className="image-description"> ({backgroundImageWord}) </div>
                  {overlayTextPositions.map((position, index) => (
                    <OverlayText key={index} position={position} index={index} />
                  ))}
                  {overlayImagePositions.map((position, index) => (
                    <OverlayImage key={index} position={position} index={index} />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <div className="bottom-clickable-div left" onClick={handlePrevious}><div className="bottom-click-div left"></div></div>
        <div className="bottom-clickable-div right" onClick={handleNext}><div className="bottom-click-div right"></div></div>
      </div>
    </div>
  );
};

export default Play;
