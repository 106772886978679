import React from 'react';

function showInstruction(instNum) {
  const trueInstNum = instNum-1;
  return (
    <div className="instructions-image-container">
      <img src={`/instructions${trueInstNum}.png`} alt={`Visual Mnemonics Instruction Number ${trueInstNum} for Visulang`} className="instructions-image" />
    </div>
  );
}

export default showInstruction;
