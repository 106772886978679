import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "./components/UserAuthContext";

const Admin = () => {
  const navigate = useNavigate();
  const { authLoaded, user, updateTable } = useUserAuth();
  const [submitUsername, setSubmitUsername] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const result = await updateTable('profiles', { paymentReceived: true }, { username: submitUsername });
      setSuccess('Payment status updated successfully!');
    } catch (err) {
      setError(err.message || 'An error occurred');
    }
  };

  const handleChange = (event) => {
    setSubmitUsername(event.target.value);
  };

  if (!authLoaded || !user) return "";

  if (user.id !== "80cd7f6e-860c-4203-a8b2-2fd487b9358b") return "";

  return (
    <div>
      <h1>Admin Panel</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={submitUsername}
          onChange={handleChange}
          required
        />
        <button type="submit">Update Payment Status</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </div>
  );
};

export default Admin;
