import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import './css/Play.css';
import './css/About.css';

const Play = () => {
  document.title = 'Visulang: Word Learning About';
  const [imageIndex, setImageIndex] = useState(0);
  const [aboutPageText, setAboutPageText] = useState([]);
  const [nextButtonVisible, setNextButtonVisible] = useState(false);

  const handleNext = () => {
    setImageIndex((prevIndex) => Math.min(prevIndex + 1, 2)); // Prevent going above 2
  };

  const handlePrevious = () => {
    setImageIndex((prevIndex) => Math.max(prevIndex - 1, 0) % 3);
  };

  // Add event listeners for keyboard events
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'ArrowLeft') {
        handlePrevious();
      }
    };

    // Attach the event listener to the window
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

  }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    if(imageIndex === 2)
      setNextButtonVisible(true);
    else
      setNextButtonVisible(false);
  }, [imageIndex]);


  useEffect(() => {
  const aboutPageText = [`
    <p style="font-size: 24px;"><b>Part 1</b></p>
    <p style="font-size: 18px;">🧠 Memory Boosting Tip 🧠</p>
    <p style="font-size: 16px;">Connect each small image to the big image and hold these connections for 5 seconds each.</p>
    <p style="font-size: 16px;">Don't fret about words yet; they'll come naturally, especially if English is your native language. 🤓</p>
  `,
  `    <p style="font-size: 24px;"><b>Part 2</b></p>
    <p style="font-size: 18px;">💡 Learning Takes Practice 💡</p>
    <p style="font-size: 16px;">Mistakes happen—no worries!</p>
    <p style="font-size: 16px;">Try recalling images and words in your mind first, then use the pictures if needed.</p>
    <p style="font-size: 16px;">Practice makes perfect. 📚</p>
`, `    <p style="font-size: 24px;"><b>Part 3</b></p>
    <p style="font-size: 18px;">⏰ Memory Plan ⏰</p>
    <p style="font-size: 16px;">After memorizing, recall words at set times: 1 hour, 4 hours, 24 hours, and one week later.</p>
    <p style="font-size: 16px;">Strengthen your recall ability for lasting memories. 🤩</p>
   `

];
    const updateOverlayText = () => {
      setAboutPageText(aboutPageText[imageIndex]);
    };


    updateOverlayText();
  }, [imageIndex]);

  return (
   <div>
    <div className="play-form-container">
      <div className="play-form">
        <div className="play-form-content">
          <div id="mainFrame" className="main-frame">
            <div className="about-description" dangerouslySetInnerHTML={{ __html: aboutPageText }}></div>
          </div>
        </div>
      </div>
    </div>
    <div className="button-container">
        {nextButtonVisible ? (
          <Link
            to="/"
            className='button-container-homepage'
          >
            Start
          </Link>
        ) : <>

      <div className="bottom-clickable-div left" onClick={handlePrevious}><div className="bottom-click-div left"></div></div>
      <div className="bottom-clickable-div right" onClick={handleNext}><div className="bottom-click-div right"></div></div>
      </>
       }
    </div>
  </div>


  );
};

export default Play;

