const subWordLocs = {
"Apple": [{"left":"23%","top":"11%"},{"left":"68%","top":"17%"},{"left":"28%","top":"89%"},{"left":"71%","top":"91%"}],
"Arm": [{"left":"22%","top":"17%"},{"left":"68%","top":"31%"},{"left":"19%","top":"62%"},{"left":"68%","top":"81%"}],
"Ball": [{"left":"31%","top":"14%"},{"left":"67%","top":"17%"},{"left":"22%","top":"83%"},{"left":"75%","top":"82%"}],
"Bed": [{"left":"34%","top":"16%"},{"left":"76%","top":"30%"},{"left":"25%","top":"84%"},{"left":"68%","top":"85%"}],
"Bicycle": [{"left":"26%","top":"23%"},{"left":"63%","top":"31%"},{"left":"26%","top":"82%"},{"left":"73%","top":"84%"}],
"Boat": [{"left":"34%","top":"27%"},{"left":"75%","top":"28%"},{"left":"21%","top":"77%"},{"left":"67%","top":"72%"}],
"Book": [{"left":"27%","top":"22%"},{"left":"61%","top":"36%"},{"left":"24%","top":"70%"},{"left":"65%","top":"84%"}],
"Bottle": [{"left":"22%","top":"23%"},{"left":"16%","top":"42%"},{"left":"83%","top":"67%"},{"left":"48%","top":"91%"}],
"Box": [{"left":"26%","top":"14%"},{"left":"71%","top":"12%"},{"left":"34%","top":"85%"},{"left":"72%","top":"82%"}],
"Car": [{"left":"37%","top":"27%"},{"left":"74%","top":"31%"},{"left":"26%","top":"77%"},{"left":"70%","top":"77%"}],
"Chair": [{"left":"19%","top":"17%"},{"left":"83%","top":"11%"},{"left":"26%","top":"90%"},{"left":"66%","top":"91%"}],
"Clock": [{"left":"29%","top":"14%"},{"left":"71%","top":"14%"},{"left":"31%","top":"88%"},{"left":"69%","top":"88%"}],
"Cold": [{"left":"34%","top":"20%"},{"left":"85%","top":"27%"},{"left":"26%","top":"61%"},{"left":"62%","top":"94%"}],
"Dog": [{"left":"26%","top":"19%"},{"left":"71%","top":"27%"},{"left":"33%","top":"83%"},{"left":"73%","top":"83%"}],
"Door": [{"left":"22%","top":"11%"},{"left":"83%","top":"11%"},{"left":"24%","top":"92%"},{"left":"71%","top":"92%"}],
"Eight": [{"left":"24%","top":"21%"},{"left":"76%","top":"21%"},{"left":"21%","top":"82%"},{"left":"77%","top":"81%"}],
"Eye": [{"left":"33%","top":"24%"},{"left":"70%","top":"24%"},{"left":"29%","top":"79%"},{"left":"71%","top":"79%"}],
"Fish": [{"left":"42%","top":"24%"},{"left":"13%","top":"65%"},{"left":"82%","top":"72%"},{"left":"45%","top":"79%"}],
"Five": [{"left":"23%","top":"12%"},{"left":"77%","top":"13%"},{"left":"15%","top":"61%"},{"left":"50%","top":"90%"}],
"Fork": [{"left":"21%","top":"13%"},{"left":"27%","top":"55%"},{"left":"72%","top":"44%"},{"left":"74%","top":"88%"}],
"Four": [{"left":"33%","top":"13%"},{"left":"14%","top":"45%"},{"left":"81%","top":"45%"},{"left":"61%","top":"94%"}],
"Glass": [{"left":"23%","top":"11%"},{"left":"74%","top":"11%"},{"left":"28%","top":"90%"},{"left":"70%","top":"90%"}],
"Hat": [{"left":"39%","top":"17%"},{"left":"76%","top":"27%"},{"left":"22%","top":"80%"},{"left":"63%","top":"81%"}],
"Horse": [{"left":"30%","top":"18%"},{"left":"69%","top":"27%"},{"left":"31%","top":"84%"},{"left":"67%","top":"84%"}],
"Hot": [{"left":"18%","top":"13%"},{"left":"73%","top":"13%"},{"left":"29%","top":"68%"},{"left":"60%","top":"89%"}],
"Key": [{"left":"22%","top":"16%"},{"left":"29%","top":"55%"},{"left":"72%","top":"44%"},{"left":"74%","top":"84%"}],
"Kite": [{"left":"27%","top":"20%"},{"left":"73%","top":"50%"},{"left":"15%","top":"59%"},{"left":"53%","top":"80%"}],
"Knife": [{"left":"19%","top":"22%"},{"left":"25%","top":"62%"},{"left":"71%","top":"44%"},{"left":"79%","top":"80%"}],
"Large": [{"left":"31%","top":"23%"},{"left":"70%","top":"33%"},{"left":"28%","top":"70%"},{"left":"60%","top":"78%"}],
"Leg": [{"left":"16%","top":"20%"},{"left":"73%","top":"40%"},{"left":"75%","top":"61%"},{"left":"44%","top":"94%"}],
"Morning": [{"left":"19%","top":"10%"},{"left":"80%","top":"10%"},{"left":"22%","top":"90%"},{"left":"75%","top":"90%"}],
"Mouth": [{"left":"29%","top":"26%"},{"left":"69%","top":"25%"},{"left":"30%","top":"78%"},{"left":"66%","top":"77%"}],
"Nine": [{"left":"74%","top":"17%"},{"left":"15%","top":"54%"},{"left":"75%","top":"64%"},{"left":"42%","top":"90%"}],
"Nose": [{"left":"73%","top":"26%"},{"left":"16%","top":"42%"},{"left":"25%","top":"86%"},{"left":"75%","top":"86%"}],
"One": [{"left":"82%","top":"22%"},{"left":"16%","top":"35%"},{"left":"82%","top":"59%"},{"left":"25%","top":"78%"}],
"Pants": [{"left":"66%","top":"19%"},{"left":"14%","top":"45%"},{"left":"80%","top":"55%"},{"left":"40%","top":"91%"}],
"Pen": [{"left":"73%","top":"14%"},{"left":"39%","top":"45%"},{"left":"34%","top":"87%"},{"left":"77%","top":"92%"}],
"Seven": [{"left":"20%","top":"13%"},{"left":"71%","top":"14%"},{"left":"15%","top":"55%"},{"left":"69%","top":"76%"}],
"Shirt": [{"left":"29%","top":"14%"},{"left":"73%","top":"19%"},{"left":"23%","top":"87%"},{"left":"71%","top":"87%"}],
"Shoe": [{"left":"29%","top":"18%"},{"left":"66%","top":"24%"},{"left":"32%","top":"72%"},{"left":"76%","top":"85%"}],
"Six": [{"left":"80%","top":"24%"},{"left":"17%","top":"36%"},{"left":"78%","top":"49%"},{"left":"48%","top":"90%"}],
"Small": [{"left":"18%","top":"25%"},{"left":"61%","top":"24%"},{"left":"29%","top":"77%"},{"left":"78%","top":"79%"}],
"Spoon": [{"left":"16%","top":"22%"},{"left":"50%","top":"32%"},{"left":"40%","top":"72%"},{"left":"81%","top":"77%"}],
"Table": [{"left":"32%","top":"21%"},{"left":"79%","top":"32%"},{"left":"20%","top":"79%"},{"left":"63%","top":"88%"}],
"Ten": [{"left":"31%","top":"14%"},{"left":"71%","top":"17%"},{"left":"26%","top":"88%"},{"left":"64%","top":"88%"}],
"Three": [{"left":"20%","top":"9%"},{"left":"80%","top":"37%"},{"left":"17%","top":"55%"},{"left":"58%","top":"90%"}],
"Two": [{"left":"70%","top":"21%"},{"left":"18%","top":"51%"},{"left":"29%","top":"91%"},{"left":"67%","top":"92%"}],
"White": [{"left":"22%","top":"15%"},{"left":"54%","top":"26%"},{"left":"31%","top":"59%"},{"left":"80%","top":"79%"}],
"Window": [{"left":"19%","top":"13%"},{"left":"77%","top":"12%"},{"left":"19%","top":"86%"},{"left":"61%","top":"84%"}],
"Yellow": [{"left":"22%","top":"15%"},{"left":"54%","top":"27%"},{"left":"31%","top":"58%"},{"left":"81%","top":"77%"}],
"Airplane": [{"top":"14%","left":"31%"},{"top":"22%","left":"79%"},{"top":"83%","left":"18%"},{"top":"89%","left":"78%"}],
"Almond": [{"top":"12%","left":"35%"},{"top":"38%","left":"18%"},{"top":"41%","left":"83%"},{"top":"93%","left":"49%"}],
"Ankle": [{"top":"16%","left":"56%"},{"top":"80%","left":"20%"},{"top":"49%","left":"71%"},{"top":"88%","left":"77%"}],
"Apron": [{"top":"18%","left":"23%"},{"top":"40%","left":"19%"},{"top":"39%","left":"78%"},{"top":"95%","left":"49%"}],
"Art": [{"top":"9%","left":"22%"},{"top":"11%","left":"69%"},{"top":"79%","left":"16%"},{"top":"79%","left":"69%"}],
"Baby": [{"top":"12%","left":"18%"},{"top":"22%","left":"76%"},{"top":"65%","left":"17%"},{"top":"94%","left":"74%"}],
"Bag": [{"top":"19%","left":"20%"},{"top":"20%","left":"79%"},{"top":"90%","left":"27%"},{"top":"92%","left":"72%"}],
"Balloon": [{"top":"14%","left":"22%"},{"top":"63%","left":"17%"},{"top":"66%","left":"75%"},{"top":"92%","left":"51%"}],
"Bathroom": [{"top":"10%","left":"24%"},{"top":"10%","left":"80%"},{"top":"94%","left":"21%"},{"top":"91%","left":"72%"}],
"Bear": [{"top":"9%","left":"76%"},{"top":"28%","left":"19%"},{"top":"93%","left":"29%"},{"top":"94%","left":"72%"}],
"Bee": [{"top":"11%","left":"69%"},{"top":"20%","left":"22%"},{"top":"75%","left":"77%"},{"top":"91%","left":"45%"}],
"Belt": [{"top":"14%","left":"24%"},{"top":"16%","left":"71%"},{"top":"82%","left":"21%"},{"top":"80%","left":"69%"}],
"Bench": [{"top":"11%","left":"72%"},{"top":"22%","left":"18%"},{"top":"71%","left":"78%"},{"top":"91%","left":"51%"}],
"Bird": [{"top":"17%","left":"25%"},{"top":"19%","left":"79%"},{"top":"40%","left":"18%"},{"top":"90%","left":"59%"}],
"Bone": [{"top":"24%","left":"20%"},{"top":"24%","left":"80%"},{"top":"75%","left":"29%"},{"top":"75%","left":"72%"}],
"Bowl": [{"top":"19%","left":"29%"},{"top":"19%","left":"72%"},{"top":"80%","left":"33%"},{"top":"81%","left":"66%"}],
"Bracelet": [{"top":"14%","left":"31%"},{"top":"18%","left":"74%"},{"top":"83%","left":"25%"},{"top":"87%","left":"67%"}],
"Brain": [{"top":"13%","left":"29%"},{"top":"19%","left":"72%"},{"top":"73%","left":"27%"},{"top":"88%","left":"57%"}],
"Bread": [{"top":"12%","left":"58%"},{"top":"31%","left":"18%"},{"top":"72%","left":"81%"},{"top":"90%","left":"46%"}],
"Broom": [{"top":"21%","left":"24%"},{"top":"44%","left":"71%"},{"top":"70%","left":"19%"},{"top":"94%","left":"78%"}],
"Bucket": [{"top":"9%","left":"21%"},{"top":"10%","left":"80%"},{"top":"93%","left":"32%"},{"top":"94%","left":"68%"}],
"Cake": [{"top":"13%","left":"33%"},{"top":"25%","left":"78%"},{"top":"87%","left":"25%"},{"top":"85%","left":"76%"}],
"Candy": [{"top":"13%","left":"27%"},{"top":"21%","left":"73%"},{"top":"70%","left":"18%"},{"top":"91%","left":"55%"}],
"Carrot": [{"top":"18%","left":"58%"},{"top":"56%","left":"20%"},{"top":"33%","left":"69%"},{"top":"92%","left":"72%"}],
"Castle": [{"top":"14%","left":"27%"},{"top":"16%","left":"74%"},{"top":"95%","left":"26%"},{"top":"95%","left":"72%"}],
"Cat": [{"top":"9%","left":"19%"},{"top":"10%","left":"71%"},{"top":"91%","left":"25%"},{"top":"91%","left":"72%"}],
"Cheese": [{"top":"16%","left":"27%"},{"top":"24%","left":"68%"},{"top":"78%","left":"23%"},{"top":"86%","left":"77%"}],
"Cherry": [{"top":"35%","left":"20%"},{"top":"40%","left":"75%"},{"top":"90%","left":"25%"},{"top":"93%","left":"68%"}],
"Chicken": [{"top":"10%","left":"18%"},{"top":"20%","left":"68%"},{"top":"76%","left":"20%"},{"top":"90%","left":"69%"}],
"Chocolate": [{"top":"10%","left":"20%"},{"top":"27%","left":"74%"},{"top":"76%","left":"24%"},{"top":"93%","left":"61%"}],
"Coffee": [{"top":"29%","left":"28%"},{"top":"35%","left":"77%"},{"top":"92%","left":"26%"},{"top":"91%","left":"66%"}],
"Coin": [{"top":"12%","left":"25%"},{"top":"29%","left":"71%"},{"top":"90%","left":"27%"},{"top":"88%","left":"75%"}],
"Cookie": [{"top":"12%","left":"25%"},{"top":"15%","left":"73%"},{"top":"87%","left":"20%"},{"top":"90%","left":"70%"}],
"Couch": [{"top":"23%","left":"28%"},{"top":"26%","left":"75%"},{"top":"80%","left":"19%"},{"top":"79%","left":"79%"}],
"Cow": [{"top":"14%","left":"25%"},{"top":"18%","left":"72%"},{"top":"85%","left":"34%"},{"top":"85%","left":"77%"}],
"Doctor": [{"top":"13%","left":"68%"},{"top":"27%","left":"17%"},{"top":"89%","left":"19%"},{"top":"52%","left":"82%"}],
"Doll": [{"top":"10%","left":"23%"},{"top":"29%","left":"81%"},{"top":"50%","left":"17%"},{"top":"85%","left":"66%"}],
"Dress": [{"top":"11%","left":"23%"},{"top":"11%","left":"74%"},{"top":"87%","left":"26%"},{"top":"86%","left":"73%"}],
"Duck": [{"top":"13%","left":"27%"},{"top":"30%","left":"73%"},{"top":"88%","left":"25%"},{"top":"90%","left":"71%"}],
"Earring": [{"top":"16%","left":"28%"},{"top":"16%","left":"69%"},{"top":"89%","left":"30%"},{"top":"85%","left":"74%"}],
"Egg": [{"top":"15%","left":"73%"},{"top":"27%","left":"15%"},{"top":"41%","left":"84%"},{"top":"95%","left":"55%"}],
"Face": [{"top":"14%","left":"29%"},{"top":"22%","left":"73%"},{"top":"73%","left":"15%"},{"top":"94%","left":"59%"}],
"Farm": [{"top":"16%","left":"28%"},{"top":"17%","left":"73%"},{"top":"92%","left":"26%"},{"top":"92%","left":"73%"}],
"Finger": [{"top":"18%","left":"23%"},{"top":"39%","left":"20%"},{"top":"62%","left":"77%"},{"top":"94%","left":"43%"}],
"Fire": [{"top":"10%","left":"72%"},{"top":"32%","left":"19%"},{"top":"37%","left":"80%"},{"top":"94%","left":"50%"}],
"Flour": [{"top":"12%","left":"27%"},{"top":"12%","left":"77%"},{"top":"91%","left":"28%"},{"top":"91%","left":"69%"}],
"Flower": [{"top":"13%","left":"26%"},{"top":"13%","left":"73%"},{"top":"86%","left":"21%"},{"top":"92%","left":"63%"}],
"Fruit": [{"top":"18%","left":"30%"},{"top":"26%","left":"72%"},{"top":"82%","left":"30%"},{"top":"82%","left":"64%"}],
"Garlic": [{"top":"11%","left":"68%"},{"top":"38%","left":"25%"},{"top":"46%","left":"75%"},{"top":"93%","left":"49%"}],
"Glove": [{"top":"8%","left":"20%"},{"top":"10%","left":"77%"},{"top":"56%","left":"29%"},{"top":"93%","left":"66%"}],
"Grape": [{"top":"11%","left":"25%"},{"top":"15%","left":"72%"},{"top":"46%","left":"12%"},{"top":"90%","left":"70%"}],
"Green": [{"left":"29%","top":"11%"},{"left":"71%","top":"11%"},{"left":"27%","top":"89%"},{"left":"70%","top":"89%"}],
"Gum": [{"left":"30%","top":"21%"},{"left":"12%","top":"24%"},{"left":"60%","top":"58%"},{"left":"66%","top":"80%"}],
"Hammer": [{"left":"30%","top":"21%"},{"left":"12%","top":"24%"},{"left":"60%","top":"58%"},{"left":"66%","top":"80%"}],
"Hand": [{"left":"31%","top":"12%"},{"left":"77%","top":"19%"},{"left":"22%","top":"46%"},{"left":"66%","top":"93%"}],
"Head": [{"left":"22%","top":"20%"},{"left":"12%","top":"38%"},{"left":"75%","top":"62%"},{"left":"74%","top":"82%"}],
"Health": [{"left":"18%","top":"9%"},{"left":"73%","top":"24%"},{"left":"12%","top":"81%"},{"left":"74%","top":"81%"}],
"Heart": [{"left":"27%","top":"9%"},{"left":"79%","top":"9%"},{"left":"28%","top":"70%"},{"left":"69%","top":"74%"}],
"Heavy": [{"left":"30%","top":"9%"},{"left":"70%","top":"9%"},{"left":"16%","top":"70%"},{"left":"82%","top":"70%"}],
"Help": [{"left":"77%","top":"9%"},{"left":"25%","top":"28%"},{"left":"20%","top":"94%"},{"left":"73%","top":"94%"}],
"History": [{"left":"24%","top":"9%"},{"left":"70%","top":"12%"},{"left":"27%","top":"93%"},{"left":"73%","top":"93%"}],
"Hook": [{"left":"75%","top":"11%"},{"left":"75%","top":"51%"},{"left":"15%","top":"54%"},{"left":"58%","top":"90%"}],
"Hose": [{"left":"16%","top":"9%"},{"left":"73%","top":"25%"},{"left":"24%","top":"90%"},{"left":"72%","top":"92%"}],
"Hospital": [{"left":"27%","top":"21%"},{"left":"71%","top":"22%"},{"left":"19%","top":"94%"},{"left":"77%","top":"94%"}],
"House": [{"left":"25%","top":"17%"},{"left":"76%","top":"17%"},{"left":"23%","top":"92%"},{"left":"73%","top":"92%"}],
"Husband": [{"left":"14%","top":"19%"},{"left":"14%","top":"30%"},{"left":"77%","top":"46%"},{"left":"58%","top":"88%"}],
"Jug": [{"left":"30%","top":"9%"},{"left":"74%","top":"24%"},{"left":"27%","top":"83%"},{"left":"70%","top":"88%"}],
"Left": [{"left":"20%","top":"19%"},{"left":"12%","top":"37%"},{"left":"79%","top":"36%"},{"left":"50%","top":"92%"}],
"Lemon": [{"left":"26%","top":"13%"},{"left":"72%","top":"22%"},{"left":"25%","top":"86%"},{"left":"74%","top":"87%"}],
"Lentil": [{"left":"28%","top":"17%"},{"left":"72%","top":"18%"},{"left":"28%","top":"82%"},{"left":"66%","top":"84%"}],
"Lion": [{"left":"24%","top":"14%"},{"left":"82%","top":"29%"},{"left":"22%","top":"81%"},{"left":"66%","top":"92%"}],
"Long": [{"left":"34%","top":"18%"},{"left":"65%","top":"24%"},{"left":"25%","top":"86%"},{"left":"75%","top":"90%"}],
"Luggage": [{"left":"27%","top":"13%"},{"left":"61%","top":"21%"},{"left":"80%","top":"48%"},{"left":"73%","top":"90%"}],
"Man": [{"left":"50%","top":"20%"},{"left":"18%","top":"31%"},{"left":"78%","top":"34%"},{"left":"50%","top":"89%"}],
"Mattress": [{"left":"39%","top":"18%"},{"left":"16%","top":"24%"},{"left":"79%","top":"40%"},{"left":"66%","top":"86%"}],
"Milk": [{"left":"30%","top":"9%"},{"left":"69%","top":"9%"},{"left":"31%","top":"91%"},{"left":"68%","top":"91%"}],
"Mirror": [{"left":"25%","top":"17%"},{"left":"12%","top":"33%"},{"left":"84%","top":"37%"},{"left":"50%","top":"93%"}],
"Money": [{"left":"38%","top":"14%"},{"left":"71%","top":"23%"},{"left":"23%","top":"86%"},{"left":"69%","top":"92%"}],
"Monkey": [{"left":"25%","top":"18%"},{"left":"75%","top":"18%"},{"left":"22%","top":"88%"},{"left":"73%","top":"92%"}],
"Month": [{"left":"27%","top":"15%"},{"left":"70%","top":"15%"},{"left":"27%","top":"85%"},{"left":"70%","top":"85%"}],
"Mouse": [{"left":"30%","top":"14%"},{"left":"78%","top":"24%"},{"left":"17%","top":"78%"},{"left":"65%","top":"88%"}],
"Mushroom": [{"left":"27%","top":"12%"},{"left":"12%","top":"30%"},{"left":"77%","top":"36%"},{"left":"51%","top":"91%"}],
"Name": [{"left":"23%","top":"20%"},{"left":"77%","top":"20%"},{"left":"21%","top":"80%"},{"left":"75%","top":"80%"}],
"Nest": [{"left":"32%","top":"21%"},{"left":"74%","top":"29%"},{"left":"24%","top":"85%"},{"left":"73%","top":"84%"}],
"North": [{"left":"20%","top":"20%"},{"left":"12%","top":"29%"},{"left":"79%","top":"34%"},{"left":"54%","top":"92%"}],
"Oat": [{"left":"30%","top":"9%"},{"left":"66%","top":"12%"},{"left":"26%","top":"90%"},{"left":"74%","top":"88%"}],
"Office": [{"left":"24%","top":"21%"},{"left":"12%","top":"38%"},{"left":"78%","top":"39%"},{"left":"49%","top":"92%"}],
"Oil": [{"left":"25%","top":"19%"},{"left":"12%","top":"37%"},{"left":"76%","top":"55%"},{"left":"50%","top":"92%"}],
"Onion": [{"left":"23%","top":"21%"},{"left":"12%","top":"40%"},{"left":"80%","top":"45%"},{"left":"73%","top":"81%"}],
"Oven": [{"left":"29%","top":"9%"},{"left":"70%","top":"11%"},{"left":"30%","top":"88%"},{"left":"69%","top":"90%"}],
"Owl": [{"left":"28%","top":"11%"},{"left":"70%","top":"12%"},{"left":"27%","top":"82%"},{"left":"73%","top":"83%"}],
"Oxygen": [{"left":"68%","top":"14%"},{"left":"14%","top":"34%"},{"left":"71%","top":"50%"},{"left":"63%","top":"80%"}],
"Pear": [{"left":"30%","top":"12%"},{"left":"61%","top":"38%"},{"left":"21%","top":"85%"},{"left":"66%","top":"92%"}],
"Pencil": [{"left":"25%","top":"9%"},{"left":"59%","top":"24%"},{"left":"35%","top":"68%"},{"left":"59%","top":"88%"}],
"Pepper": [{"left":"22%","top":"19%"},{"left":"39%","top":"31%"},{"left":"40%","top":"83%"},{"left":"78%","top":"90%"}],
"Pig": [{"left":"25%","top":"13%"},{"left":"68%","top":"14%"},{"left":"22%","top":"75%"},{"left":"73%","top":"92%"}],
"Pink": [{"left":"20%","top":"20%"},{"left":"14%","top":"29%"},{"left":"76%","top":"32%"},{"left":"66%","top":"88%"}],
"Plum": [{"left":"60%","top":"16%"},{"left":"14%","top":"35%"},{"left":"83%","top":"45%"},{"left":"72%","top":"85%"}],
"Pool": [{"left":"30%","top":"33%"},{"left":"70%","top":"35%"},{"left":"20%","top":"74%"},{"left":"75%","top":"76%"}],
"Popcorn": [{"left":"17%","top":"20%"},{"left":"14%","top":"64%"},{"left":"79%","top":"76%"},{"left":"76%","top":"89%"}],
"Purple": [{"left":"33%","top":"12%"},{"left":"11%","top":"77%"},{"left":"53%","top":"82%"},{"left":"77%","top":"90%"}],
"Rabbit": [{"left":"27%","top":"8%"},{"left":"67%","top":"11%"},{"left":"72%","top":"51%"},{"left":"41%","top":"93%"}],
"Rag": [{"left":"20%","top":"21%"},{"left":"12%","top":"40%"},{"left":"78%","top":"46%"},{"left":"68%","top":"92%"}],
"Red": [{"left":"43%","top":"21%"},{"left":"58%","top":"35%"},{"left":"70%","top":"48%"},{"left":"52%","top":"79%"}],
"Rice": [{"left":"31%","top":"17%"},{"left":"80%","top":"29%"},{"left":"32%","top":"81%"},{"left":"70%","top":"88%"}],
"Salt": [{"left":"21%","top":"20%"},{"left":"12%","top":"38%"},{"left":"75%","top":"52%"},{"left":"71%","top":"84%"}],
"Sandal": [{"left":"33%","top":"21%"},{"left":"77%","top":"25%"},{"left":"27%","top":"83%"},{"left":"72%","top":"86%"}],
"Scissors": [{"left":"22%","top":"19%"},{"left":"14%","top":"28%"},{"left":"71%","top":"46%"},{"left":"56%","top":"85%"}],
"Scooter": [{"left":"25%","top":"11%"},{"left":"11%","top":"35%"},{"left":"15%","top":"90%"},{"left":"66%","top":"90%"}],
"Shampoo": [{"left":"20%","top":"18%"},{"left":"16%","top":"34%"},{"left":"77%","top":"50%"},{"left":"77%","top":"83%"}],
"Sheep": [{"left":"27%","top":"13%"},{"left":"69%","top":"25%"},{"left":"34%","top":"90%"},{"left":"66%","top":"92%"}],
"Short": [{"left":"20%","top":"9%"},{"left":"65%","top":"23%"},{"left":"15%","top":"68%"},{"left":"76%","top":"84%"}],
"Shovel": [{"left":"40%","top":"21%"},{"left":"61%","top":"36%"},{"left":"70%","top":"52%"},{"left":"77%","top":"92%"}],
"Sister": [{"left":"24%","top":"10%"},{"left":"66%","top":"26%"},{"left":"22%","top":"82%"},{"left":"68%","top":"92%"}],
"Skin": [{"left":"36%","top":"14%"},{"left":"69%","top":"23%"},{"left":"27%","top":"92%"},{"left":"72%","top":"88%"}],
"Smile": [{"left":"30%","top":"17%"},{"left":"13%","top":"29%"},{"left":"79%","top":"54%"},{"left":"76%","top":"83%"}],
"Snake": [{"left":"25%","top":"9%"},{"left":"69%","top":"21%"},{"left":"20%","top":"87%"},{"left":"75%","top":"92%"}],
"Sock": [{"left":"20%","top":"9%"},{"left":"12%","top":"24%"},{"left":"71%","top":"58%"},{"left":"66%","top":"92%"}],
"Soup": [{"left":"74%","top":"17%"},{"left":"16%","top":"39%"},{"left":"84%","top":"55%"},{"left":"72%","top":"84%"}],
"South": [{"left":"30%","top":"20%"},{"left":"64%","top":"21%"},{"left":"68%","top":"66%"},{"left":"78%","top":"80%"}],
"Spinach": [{"left":"20%","top":"9%"},{"left":"63%","top":"24%"},{"left":"22%","top":"70%"},{"left":"77%","top":"80%"}],
"Strawberry": [{"left":"30%","top":"20%"},{"left":"14%","top":"31%"},{"left":"70%","top":"72%"},{"left":"67%","top":"81%"}],
"Study": [{"left":"70%","top":"15%"},{"left":"12%","top":"34%"},{"left":"75%","top":"44%"},{"left":"66%","top":"86%"}],
"Taco": [{"left":"50%","top":"21%"},{"left":"16%","top":"31%"},{"left":"75%","top":"72%"},{"left":"50%","top":"83%"}],
"Tea": [{"left":"30%","top":"18%"},{"left":"79%","top":"31%"},{"left":"21%","top":"84%"},{"left":"64%","top":"80%"}],
"Teacher": [{"left":"27%","top":"21%"},{"left":"78%","top":"27%"},{"left":"20%","top":"79%"},{"left":"58%","top":"88%"}],
"Toaster": [{"left":"25%","top":"12%"},{"left":"65%","top":"15%"},{"left":"30%","top":"84%"},{"left":"64%","top":"93%"}],
"Tomato": [{"left":"30%","top":"19%"},{"left":"68%","top":"21%"},{"left":"27%","top":"84%"},{"left":"66%","top":"92%"}],
"Tree": [{"left":"28%","top":"16%"},{"left":"14%","top":"24%"},{"left":"18%","top":"72%"},{"left":"77%","top":"80%"}],
"Triangle": [{"left":"21%","top":"21%"},{"left":"16%","top":"35%"},{"left":"73%","top":"38%"},{"left":"74%","top":"84%"}],
"Truck": [{"left":"23%","top":"9%"},{"left":"62%","top":"24%"},{"left":"29%","top":"74%"},{"left":"63%","top":"92%"}],
"Wallet": [{"left":"21%","top":"22%"},{"left":"77%","top":"20%"},{"left":"21%","top":"82%"},{"left":"63%","top":"86%"}],
"Want": [{"left":"28%","top":"18%"},{"left":"70%","top":"18%"},{"left":"31%","top":"82%"},{"left":"66%","top":"85%"}],
"Water": [{"left":"15%","top":"22%"},{"left":"76%","top":"17%"},{"left":"22%","top":"83%"},{"left":"61%","top":"85%"}],
"Watermelon": [{"left":"30%","top":"20%"},{"left":"17%","top":"29%"},{"left":"27%","top":"81%"},{"left":"65%","top":"88%"}],
"Wheel": [{"left":"29%","top":"12%"},{"left":"67%","top":"12%"},{"left":"28%","top":"84%"},{"left":"67%","top":"92%"}],
};
export default subWordLocs;
