const baseImageLocs = { 
"Apple": [{"top":"23%","left":"26%"},{"top":"30%","left":"69%"},{"top":"77%","left":"33%"},{"top":"79%","left":"62%"}],
"Arm": [{"top":"30%","left":"26%"},{"top":"45%","left":"69%"},{"top":"59%","left":"43%"},{"top":"68%","left":"64%"}],
"Ball": [{"top":"29%","left":"36%"},{"top":"31%","left":"66%"},{"top":"70%","left":"33%"},{"top":"70%","left":"65%"}],
"Bed": [{"top":"31%","left":"36%"},{"top":"42%","left":"68%"},{"top":"70%","left":"36%"},{"top":"70%","left":"68%"}],
"Bicycle": [{"top":"36%","left":"27%"},{"top":"44%","left":"60%"},{"top":"69%","left":"26%"},{"top":"71%","left":"73%"}],
"Boat": [{"top":"42%","left":"35%"},{"top":"42%","left":"76%"},{"top":"64%","left":"21%"},{"top":"58%","left":"67%"}],
"Book": [{"top":"35%","left":"28%"},{"top":"49%","left":"61%"},{"top":"58%","left":"40%"},{"top":"71%","left":"66%"}],
"Bottle": [{"top":"25%","left":"50%"},{"top":"54%","left":"37%"},{"top":"57%","left":"63%"},{"top":"77%","left":"49%"}],
"Box": [{"top":"31%","left":"31%"},{"top":"28%","left":"69%"},{"top":"69%","left":"35%"},{"top":"69%","left":"67%"}],
"Car": [{"top":"41%","left":"42%"},{"top":"44%","left":"70%"},{"top":"64%","left":"28%"},{"top":"64%","left":"71%"}],
"Chair": [{"top":"24%","left":"43%"},{"top":"24%","left":"69%"},{"top":"76%","left":"29%"},{"top":"76%","left":"58%"}],
"Clock": [{"top":"30%","left":"33%"},{"top":"30%","left":"67%"},{"top":"70%","left":"34%"},{"top":"72%","left":"67%"}],
"Cold": [{"top":"20%","left":"58%"},{"top":"39%","left":"67%"},{"top":"62%","left":"51%"},{"top":"81%","left":"63%"}],
"Dog": [{"top":"33%","left":"28%"},{"top":"41%","left":"69%"},{"top":"70%","left":"36%"},{"top":"70%","left":"74%"}],
"Door": [{"top":"25%","left":"35%"},{"top":"23%","left":"66%"},{"top":"77%","left":"34%"},{"top":"79%","left":"66%"}],
"Eight": [{"top":"34%","left":"33%"},{"top":"33%","left":"67%"},{"top":"69%","left":"30%"},{"top":"69%","left":"69%"}],
"Eye": [{"top":"41%","left":"34%"},{"top":"39%","left":"70%"},{"top":"64%","left":"30%"},{"top":"64%","left":"70%"}],
"Fish": [{"top":"38%","left":"40%"},{"top":"52%","left":"19%"},{"top":"59%","left":"81%"},{"top":"65%","left":"46%"}],
"Five": [{"top":"25%","left":"33%"},{"top":"25%","left":"68%"},{"top":"52%","left":"39%"},{"top":"75%","left":"50%"}],
"Fork": [{"top":"26%","left":"19%"},{"top":"42%","left":"39%"},{"top":"57%","left":"66%"},{"top":"72%","left":"77%"}],
"Four": [{"top":"24%","left":"56%"},{"top":"57%","left":"28%"},{"top":"59%","left":"73%"},{"top":"78%","left":"61%"}],
"Glass": [{"top":"26%","left":"30%"},{"top":"26%","left":"71%"},{"top":"74%","left":"30%"},{"top":"74%","left":"70%"}],
"Hat": [{"top":"30%","left":"42%"},{"top":"40%","left":"76%"},{"top":"65%","left":"25%"},{"top":"66%","left":"63%"}],
"Horse": [{"top":"32%","left":"30%"},{"top":"42%","left":"67%"},{"top":"69%","left":"32%"},{"top":"70%","left":"67%"}],
"Hot": [{"top":"25%","left":"40%"},{"top":"28%","left":"68%"},{"top":"56%","left":"37%"},{"top":"77%","left":"60%"}],
"Key": [{"top":"33%","left":"23%"},{"top":"43%","left":"38%"},{"top":"55%","left":"58%"},{"top":"72%","left":"74%"}],
"Kite": [{"top":"24%","left":"55%"},{"top":"38%","left":"74%"},{"top":"58%","left":"39%"},{"top":"78%","left":"27%"}],
"Knife": [{"top":"37%","left":"21%"},{"top":"50%","left":"43%"},{"top":"56%","left":"60%"},{"top":"67%","left":"81%"}],
"Large": [{"top":"37%","left":"32%"},{"top":"46%","left":"69%"},{"top":"57%","left":"37%"},{"top":"65%","left":"60%"}],
"Leg": [{"top":"23%","left":"46%"},{"top":"40%","left":"53%"},{"top":"61%","left":"52%"},{"top":"80%","left":"44%"}],
"Morning": [{"top":"25%","left":"25%"},{"top":"25%","left":"75%"},{"top":"75%","left":"25%"},{"top":"75%","left":"75%"}],
"Mouth": [{"top":"40%","left":"32%"},{"top":"40%","left":"69%"},{"top":"64%","left":"33%"},{"top":"64%","left":"65%"}],
"Nine": [{"top":"28%","left":"50%"},{"top":"42%","left":"30%"},{"top":"51%","left":"70%"},{"top":"74%","left":"43%"}],
"Nose": [{"top":"31%","left":"50%"},{"top":"53%","left":"37%"},{"top":"71%","left":"25%"},{"top":"72%","left":"75%"}],
"One": [{"top":"28%","left":"60%"},{"top":"36%","left":"40%"},{"top":"60%","left":"60%"},{"top":"77%","left":"51%"}],
"Pants": [{"top":"20%","left":"43%"},{"top":"46%","left":"41%"},{"top":"66%","left":"59%"},{"top":"79%","left":"41%"}],
"Pen": [{"top":"27%","left":"58%"},{"top":"45%","left":"68%"},{"top":"71%","left":"36%"},{"top":"76%","left":"78%"}],
"Seven": [{"top":"25%","left":"33%"},{"top":"25%","left":"69%"},{"top":"56%","left":"44%"},{"top":"74%","left":"45%"}],
"Shirt": [{"top":"29%","left":"31%"},{"top":"34%","left":"73%"},{"top":"74%","left":"31%"},{"top":"74%","left":"67%"}],
"Shoe": [{"top":"33%","left":"31%"},{"top":"36%","left":"63%"},{"top":"60%","left":"38%"},{"top":"72%","left":"79%"}],
"Six": [{"top":"25%","left":"56%"},{"top":"48%","left":"34%"},{"top":"61%","left":"69%"},{"top":"77%","left":"49%"}],
"Small": [{"top":"41%","left":"21%"},{"top":"38%","left":"59%"},{"top":"64%","left":"30%"},{"top":"66%","left":"79%"}],
"Spoon": [{"top":"37%","left":"18%"},{"top":"44%","left":"39%"},{"top":"60%","left":"60%"},{"top":"65%","left":"82%"}],
"Table": [{"top":"34%","left":"36%"},{"top":"45%","left":"80%"},{"top":"66%","left":"21%"},{"top":"73%","left":"64%"}],
"Ten": [{"top":"28%","left":"32%"},{"top":"30%","left":"70%"},{"top":"72%","left":"29%"},{"top":"72%","left":"63%"}],
"Three": [{"top":"24%","left":"33%"},{"top":"25%","left":"70%"},{"top":"68%","left":"29%"},{"top":"76%","left":"59%"}],
"Two": [{"top":"24%","left":"50%"},{"top":"38%","left":"30%"},{"top":"76%","left":"31%"},{"top":"77%","left":"68%"}],
"White": [{"top":"29%","left":"23%"},{"top":"38%","left":"43%"},{"top":"59%","left":"58%"},{"top":"64%","left":"81%"}],
"Window": [{"top":"29%","left":"19%"},{"top":"27%","left":"77%"},{"top":"72%","left":"22%"},{"top":"71%","left":"61%"}],
"Yellow": [{"top":"28%","left":"24%"},{"top":"39%","left":"45%"},{"top":"59%","left":"57%"},{"top":"64%","left":"82%"}],
"Airplane": [{"left":"32%","top":"28%"},{"left":"81%","top":"36%"},{"left":"20%","top":"71%"},{"left":"78%","top":"76%"}],
"Almond": [{"left":"57%","top":"24%"},{"left":"36%","top":"50%"},{"left":"67%","top":"53%"},{"left":"49%","top":"81%"}],
"Ankle": [{"left":"39%","top":"28%"},{"left":"20%","top":"65%"},{"left":"55%","top":"61%"},{"left":"79%","top":"74%"}],
"Apron": [{"left":"50%","top":"20%"},{"left":"35%","top":"52%"},{"left":"65%","top":"52%"},{"left":"50%","top":"83%"}],
"Art": [{"left":"24%","top":"22%"},{"left":"67%","top":"25%"},{"left":"31%","top":"67%"},{"left":"67%","top":"66%"}],
"Baby": [{"left":"42%","top":"20%"},{"left":"72%","top":"36%"},{"left":"30%","top":"52%"},{"left":"56%","top":"82%"}],
"Bag": [{"left":"30%","top":"34%"},{"left":"68%","top":"34%"},{"left":"30%","top":"75%"},{"left":"69%","top":"80%"}],
"Balloon": [{"left":"48%","top":"20%"},{"left":"24%","top":"51%"},{"left":"70%","top":"53%"},{"left":"52%","top":"79%"}],
"Bathroom": [{"left":"29%","top":"22%"},{"left":"68%","top":"22%"},{"left":"25%","top":"79%"},{"left":"71%","top":"78%"}],
"Bear": [{"left":"63%","top":"21%"},{"left":"33%","top":"41%"},{"left":"30%","top":"81%"},{"left":"72%","top":"82%"}],
"Bee": [{"left":"55%","top":"23%"},{"left":"25%","top":"36%"},{"left":"75%","top":"60%"},{"left":"45%","top":"78%"}],
"Belt": [{"left":"25%","top":"31%"},{"left":"69%","top":"33%"},{"left":"22%","top":"67%"},{"left":"67%","top":"65%"}],
"Bench": [{"left":"59%","top":"24%"},{"left":"20%","top":"37%"},{"left":"78%","top":"57%"},{"left":"52%","top":"78%"}],
"Bird": [{"left":"50%","top":"22%"},{"left":"80%","top":"33%"},{"left":"34%","top":"52%"},{"left":"59%","top":"75%"}],
"Bone": [{"left":"20%","top":"41%"},{"left":"80%","top":"41%"},{"left":"29%","top":"60%"},{"left":"70%","top":"60%"}],
"Bowl": [{"left":"29%","top":"35%"},{"left":"72%","top":"35%"},{"left":"35%","top":"66%"},{"left":"66%","top":"66%"}],
"Bracelet": [{"left":"32%","top":"29%"},{"left":"73%","top":"34%"},{"left":"26%","top":"67%"},{"left":"66%","top":"72%"}],
"Brain": [{"left":"36%","top":"27%"},{"left":"70%","top":"34%"},{"left":"32%","top":"60%"},{"left":"58%","top":"75%"}],
"Bread": [{"left":"60%","top":"25%"},{"left":"22%","top":"46%"},{"left":"82%","top":"57%"},{"left":"46%","top":"76%"}],
"Broom": [{"left":"50%","top":"25%"},{"left":"50%","top":"45%"},{"left":"43%","top":"69%"},{"left":"59%","top":"83%"}],
"Bucket": [{"left":"23%","top":"24%"},{"left":"80%","top":"24%"},{"left":"33%","top":"78%"},{"left":"68%","top":"78%"}],
"Cake": [{"left":"38%","top":"27%"},{"left":"74%","top":"39%"},{"left":"26%","top":"74%"},{"left":"77%","top":"72%"}],
"Candy": [{"left":"43%","top":"25%"},{"left":"70%","top":"37%"},{"left":"24%","top":"56%"},{"left":"52%","top":"78%"}],
"Carrot": [{"left":"31%","top":"24%"},{"left":"30%","top":"42%"},{"left":"61%","top":"50%"},{"left":"77%","top":"79%"}],
"Castle": [{"left":"31%","top":"30%"},{"left":"73%","top":"31%"},{"left":"26%","top":"83%"},{"left":"73%","top":"83%"}],
"Cat": [{"left":"37%","top":"21%"},{"left":"59%","top":"22%"},{"left":"32%","top":"75%"},{"left":"68%","top":"75%"}],
"Cheese": [{"left":"30%","top":"32%"},{"left":"66%","top":"40%"},{"left":"23%","top":"63%"},{"left":"77%","top":"71%"}],
"Cherry": [{"left":"37%","top":"22%"},{"left":"76%","top":"24%"},{"left":"26%","top":"74%"},{"left":"68%","top":"78%"}],
"Chicken": [{"left":"34%","top":"22%"},{"left":"68%","top":"34%"},{"left":"33%","top":"62%"},{"left":"58%","top":"77%"}],
"Chocolate": [{"left":"43%","top":"22%"},{"left":"60%","top":"42%"},{"left":"43%","top":"62%"},{"left":"62%","top":"80%"}],
"Coffee": [{"left":"35%","top":"46%"},{"left":"75%","top":"52%"},{"left":"28%","top":"76%"},{"left":"66%","top":"76%"}],
"Coin": [{"left":"27%","top":"28%"},{"left":"52%","top":"49%"},{"left":"22%","top":"72%"},{"left":"76%","top":"72%"}],
"Cookie": [{"left":"36%","top":"26%"},{"left":"69%","top":"30%"},{"left":"28%","top":"70%"},{"left":"66%","top":"75%"}],
"Couch": [{"left":"29%","top":"40%"},{"left":"76%","top":"42%"},{"left":"20%","top":"65%"},{"left":"81%","top":"64%"}],
"Cow": [{"left":"24%","top":"30%"},{"left":"73%","top":"35%"},{"left":"36%","top":"70%"},{"left":"78%","top":"70%"}],
"Doctor": [{"left":"55%","top":"25%"},{"left":"33%","top":"39%"},{"left":"21%","top":"75%"},{"left":"79%","top":"68%"}],
"Doll": [{"left":"47%","top":"22%"},{"left":"68%","top":"41%"},{"left":"31%","top":"64%"},{"left":"67%","top":"71%"}],
"Dress": [{"left":"27%","top":"25%"},{"left":"71%","top":"26%"},{"left":"26%","top":"73%"},{"left":"73%","top":"71%"}],
"Duck": [{"left":"33%","top":"27%"},{"left":"74%","top":"45%"},{"left":"27%","top":"75%"},{"left":"73%","top":"76%"}],
"Earring": [{"left":"31%","top":"32%"},{"left":"68%","top":"31%"},{"left":"27%","top":"73%"},{"left":"74%","top":"69%"}],
"Egg": [{"left":"50%","top":"21%"},{"left":"30%","top":"45%"},{"left":"70%","top":"54%"},{"left":"49%","top":"81%"}],
"Face": [{"left":"36%","top":"30%"},{"left":"71%","top":"36%"},{"left":"28%","top":"59%"},{"left":"52%","top":"81%"}],
"Farm": [{"left":"31%","top":"31%"},{"left":"70%","top":"32%"},{"left":"27%","top":"78%"},{"left":"74%","top":"77%"}],
"Finger": [{"left":"50%","top":"21%"},{"left":"46%","top":"42%"},{"left":"55%","top":"63%"},{"left":"45%","top":"81%"}],
"Fire": [{"left":"57%","top":"22%"},{"left":"33%","top":"46%"},{"left":"70%","top":"50%"},{"left":"51%","top":"80%"}],
"Flour": [{"left":"39%","top":"25%"},{"left":"69%","top":"27%"},{"left":"28%","top":"77%"},{"left":"70%","top":"77%"}],
"Flower": [{"left":"30%","top":"28%"},{"left":"70%","top":"29%"},{"left":"27%","top":"69%"},{"left":"63%","top":"78%"}],
"Fruit": [{"left":"30%","top":"34%"},{"left":"73%","top":"42%"},{"left":"32%","top":"68%"},{"left":"63%","top":"67%"}],
"Garlic": [{"left":"58%","top":"24%"},{"left":"44%","top":"50%"},{"left":"69%","top":"62%"},{"left":"49%","top":"80%"}],
"Glove": [{"left":"30%","top":"21%"},{"left":"72%","top":"24%"},{"left":"60%","top":"58%"},{"left":"66%","top":"80%"}],
"Grape": [{"left":"30%","top":"26%"},{"left":"72%","top":"31%"},{"left":"30%","top":"58%"},{"left":"72%","top":"75%"}],
"Green": [{"left":"30%","top":"24%"},{"left":"70%","top":"24%"},{"left":"30%","top":"76%"},{"left":"70%","top":"76%"}],
"Gum": [{"left":"30%","top":"21%"},{"left":"12%","top":"24%"},{"left":"60%","top":"58%"},{"left":"66%","top":"80%"}],
"Hammer": [{"left":"30%","top":"18%"},{"left":"22%","top":"29%"},{"left":"50%","top":"50%"},{"left":"86%","top":"86%"}],
"Hand": [{"left":"34%","top":"25%"},{"left":"76%","top":"31%"},{"left":"27%","top":"58%"},{"left":"66%","top":"80%"}],
"Head": [{"left":"50%","top":"21%"},{"left":"31%","top":"50%"},{"left":"69%","top":"50%"},{"left":"50%","top":"80%"}],
"Health": [{"left":"33%","top":"21%"},{"left":"70%","top":"36%"},{"left":"20%","top":"65%"},{"left":"50%","top":"80%"}],
"Heart": [{"left":"30%","top":"21%"},{"left":"70%","top":"21%"},{"left":"35%","top":"58%"},{"left":"65%","top":"58%"}],
"Heavy": [{"left":"30%","top":"21%"},{"left":"70%","top":"21%"},{"left":"30%","top":"58%"},{"left":"70%","top":"58%"}],
"Help": [{"left":"75%","top":"21%"},{"left":"30%","top":"42%"},{"left":"22%","top":"82%"},{"left":"75%","top":"82%"}],
"History": [{"left":"30%","top":"21%"},{"left":"67%","top":"24%"},{"left":"30%","top":"80%"},{"left":"72%","top":"80%"}],
"Hook": [{"left":"74%","top":"24%"},{"left":"52%","top":"46%"},{"left":"27%","top":"68%"},{"left":"57%","top":"77%"}],
"Hose": [{"left":"40%","top":"21%"},{"left":"70%","top":"38%"},{"left":"27%","top":"75%"},{"left":"73%","top":"80%"}],
"Hospital": [{"left":"33%","top":"36%"},{"left":"67%","top":"36%"},{"left":"22%","top":"82%"},{"left":"78%","top":"82%"}],
"House": [{"left":"35%","top":"30%"},{"left":"71%","top":"31%"},{"left":"26%","top":"80%"},{"left":"74%","top":"80%"}],
"Husband": [{"left":"40%","top":"21%"},{"left":"26%","top":"44%"},{"left":"77%","top":"58%"},{"left":"56%","top":"75%"}],
"Jug": [{"left":"30%","top":"21%"},{"left":"69%","top":"36%"},{"left":"33%","top":"70%"},{"left":"71%","top":"75%"}],
"Left": [{"left":"50%","top":"21%"},{"left":"25%","top":"50%"},{"left":"75%","top":"50%"},{"left":"50%","top":"80%"}],
"Lemon": [{"left":"26%","top":"26%"},{"left":"71%","top":"38%"},{"left":"25%","top":"73%"},{"left":"79%","top":"74%"}],
"Lentil": [{"left":"30%","top":"30%"},{"left":"70%","top":"30%"},{"left":"34%","top":"70%"},{"left":"69%","top":"70%"}],
"Lion": [{"left":"22%","top":"26%"},{"left":"82%","top":"43%"},{"left":"40%","top":"68%"},{"left":"66%","top":"80%"}],
"Long": [{"left":"40%","top":"31%"},{"left":"66%","top":"37%"},{"left":"27%","top":"74%"},{"left":"80%","top":"77%"}],
"Luggage": [{"left":"25%","top":"25%"},{"left":"50%","top":"33%"},{"left":"82%","top":"68%"},{"left":"50%","top":"83%"}],
"Man": [{"left":"50%","top":"33%"},{"left":"20%","top":"46%"},{"left":"80%","top":"46%"},{"left":"50%","top":"76%"}],
"Mattress": [{"left":"43%","top":"33%"},{"left":"19%","top":"40%"},{"left":"85%","top":"67%"},{"left":"69%","top":"74%"}],
"Milk": [{"left":"33%","top":"21%"},{"left":"67%","top":"21%"},{"left":"33%","top":"78%"},{"left":"67%","top":"78%"}],
"Mirror": [{"left":"50%","top":"21%"},{"left":"24%","top":"50%"},{"left":"76%","top":"50%"},{"left":"50%","top":"80%"}],
"Money": [{"left":"43%","top":"27%"},{"left":"74%","top":"35%"},{"left":"24%","top":"74%"},{"left":"69%","top":"80%"}],
"Monkey": [{"left":"25%","top":"30%"},{"left":"75%","top":"30%"},{"left":"22%","top":"75%"},{"left":"75%","top":"80%"}],
"Month": [{"left":"30%","top":"30%"},{"left":"70%","top":"30%"},{"left":"30%","top":"72%"},{"left":"70%","top":"72%"}],
"Mouse": [{"left":"31%","top":"26%"},{"left":"80%","top":"40%"},{"left":"30%","top":"66%"},{"left":"66%","top":"75%"}],
"Mushroom": [{"left":"54%","top":"24%"},{"left":"22%","top":"43%"},{"left":"81%","top":"50%"},{"left":"50%","top":"79%"}],
"Name": [{"left":"20%","top":"32%"},{"left":"80%","top":"32%"},{"left":"20%","top":"68%"},{"left":"80%","top":"68%"}],
"Nest": [{"left":"34%","top":"34%"},{"left":"77%","top":"42%"},{"left":"25%","top":"73%"},{"left":"76%","top":"69%"}],
"North": [{"left":"45%","top":"21%"},{"left":"23%","top":"50%"},{"left":"77%","top":"50%"},{"left":"55%","top":"80%"}],
"Oat": [{"left":"30%","top":"21%"},{"left":"67%","top":"24%"},{"left":"27%","top":"78%"},{"left":"79%","top":"76%"}],
"Office": [{"left":"50%","top":"24%"},{"left":"18%","top":"56%"},{"left":"82%","top":"58%"},{"left":"50%","top":"80%"}],
"Oil": [{"left":"50%","top":"18%"},{"left":"42%","top":"38%"},{"left":"60%","top":"66%"},{"left":"50%","top":"80%"}],
"Onion": [{"left":"50%","top":"21%"},{"left":"22%","top":"63%"},{"left":"78%","top":"65%"},{"left":"50%","top":"80%"}],
"Oven": [{"left":"30%","top":"21%"},{"left":"70%","top":"23%"},{"left":"30%","top":"75%"},{"left":"70%","top":"78%"}],
"Owl": [{"left":"28%","top":"23%"},{"left":"72%","top":"24%"},{"left":"28%","top":"68%"},{"left":"73%","top":"70%"}],
"Oxygen": [{"left":"70%","top":"26%"},{"left":"45%","top":"34%"},{"left":"57%","top":"62%"},{"left":"38%","top":"77%"}],
"Pear": [{"left":"31%","top":"24%"},{"left":"50%","top":"50%"},{"left":"22%","top":"73%"},{"left":"66%","top":"80%"}],
"Pencil": [{"left":"26%","top":"21%"},{"left":"60%","top":"37%"},{"left":"60%","top":"68%"},{"left":"86%","top":"86%"}],
"Pepper": [{"left":"19%","top":"31%"},{"left":"34%","top":"46%"},{"left":"40%","top":"71%"},{"left":"82%","top":"77%"}],
"Pig": [{"left":"25%","top":"25%"},{"left":"68%","top":"26%"},{"left":"37%","top":"63%"},{"left":"75%","top":"80%"}],
"Pink": [{"left":"50%","top":"21%"},{"left":"20%","top":"42%"},{"left":"80%","top":"44%"},{"left":"67%","top":"74%"}],
"Plum": [{"left":"64%","top":"28%"},{"left":"26%","top":"49%"},{"left":"75%","top":"58%"},{"left":"50%","top":"83%"}],
"Pool": [{"left":"34%","top":"46%"},{"left":"72%","top":"49%"},{"left":"17%","top":"61%"},{"left":"83%","top":"64%"}],
"Popcorn": [{"left":"46%","top":"24%"},{"left":"29%","top":"50%"},{"left":"68%","top":"58%"},{"left":"50%","top":"81%"}],
"Purple": [{"left":"37%","top":"24%"},{"left":"50%","top":"70%"},{"left":"36%","top":"80%"},{"left":"80%","top":"77%"}],
"Rabbit": [{"left":"30%","top":"20%"},{"left":"60%","top":"23%"},{"left":"67%","top":"66%"},{"left":"42%","top":"81%"}],
"Rag": [{"left":"46%","top":"21%"},{"left":"38%","top":"50%"},{"left":"63%","top":"58%"},{"left":"69%","top":"80%"}],
"Red": [{"left":"23%","top":"21%"},{"left":"38%","top":"38%"},{"left":"64%","top":"60%"},{"left":"82%","top":"77%"}],
"Rice": [{"left":"33%","top":"29%"},{"left":"83%","top":"46%"},{"left":"33%","top":"69%"},{"left":"72%","top":"76%"}],
"Salt": [{"left":"46%","top":"23%"},{"left":"40%","top":"40%"},{"left":"64%","top":"64%"},{"left":"49%","top":"80%"}],
"Sandal": [{"left":"38%","top":"38%"},{"left":"78%","top":"42%"},{"left":"27%","top":"70%"},{"left":"73%","top":"73%"}],
"Scissors": [{"left":"53%","top":"22%"},{"left":"26%","top":"40%"},{"left":"60%","top":"58%"},{"left":"82%","top":"82%"}],
"Scooter": [{"left":"30%","top":"25%"},{"left":"28%","top":"50%"},{"left":"18%","top":"76%"},{"left":"70%","top":"77%"}],
"Shampoo": [{"left":"50%","top":"18%"},{"left":"40%","top":"48%"},{"left":"59%","top":"61%"},{"left":"56%","top":"81%"}],
"Sheep": [{"left":"28%","top":"26%"},{"left":"70%","top":"38%"},{"left":"36%","top":"78%"},{"left":"66%","top":"80%"}],
"Short": [{"left":"35%","top":"20%"},{"left":"66%","top":"36%"},{"left":"34%","top":"56%"},{"left":"69%","top":"69%"}],
"Shovel": [{"left":"20%","top":"21%"},{"left":"37%","top":"37%"},{"left":"67%","top":"67%"},{"left":"83%","top":"80%"}],
"Sister": [{"left":"37%","top":"22%"},{"left":"67%","top":"38%"},{"left":"33%","top":"70%"},{"left":"70%","top":"80%"}],
"Skin": [{"left":"40%","top":"26%"},{"left":"70%","top":"36%"},{"left":"30%","top":"77%"},{"left":"75%","top":"75%"}],
"Smile": [{"left":"58%","top":"22%"},{"left":"38%","top":"40%"},{"left":"69%","top":"66%"},{"left":"45%","top":"81%"}],
"Snake": [{"left":"33%","top":"21%"},{"left":"65%","top":"33%"},{"left":"21%","top":"75%"},{"left":"77%","top":"79%"}],
"Sock": [{"left":"44%","top":"21%"},{"left":"35%","top":"36%"},{"left":"43%","top":"66%"},{"left":"70%","top":"80%"}],
"Soup": [{"left":"76%","top":"30%"},{"left":"25%","top":"52%"},{"left":"73%","top":"68%"},{"left":"50%","top":"83%"}],
"South": [{"left":"34%","top":"33%"},{"left":"64%","top":"34%"},{"left":"63%","top":"53%"},{"left":"52%","top":"79%"}],
"Spinach": [{"left":"34%","top":"21%"},{"left":"65%","top":"39%"},{"left":"33%","top":"58%"},{"left":"52%","top":"81%"}],
"Strawberry": [{"left":"60%","top":"21%"},{"left":"35%","top":"46%"},{"left":"69%","top":"59%"},{"left":"38%","top":"80%"}],
"Study": [{"left":"65%","top":"26%"},{"left":"24%","top":"47%"},{"left":"66%","top":"58%"},{"left":"34%","top":"79%"}],
"Taco": [{"left":"50%","top":"37%"},{"left":"25%","top":"50%"},{"left":"77%","top":"60%"},{"left":"50%","top":"69%"}],
"Tea": [{"left":"30%","top":"30%"},{"left":"81%","top":"44%"},{"left":"21%","top":"72%"},{"left":"65%","top":"65%"}],
"Teacher": [{"left":"55%","top":"21%"},{"left":"80%","top":"43%"},{"left":"37%","top":"67%"},{"left":"58%","top":"76%"}],
"Toaster": [{"left":"41%","top":"24%"},{"left":"63%","top":"27%"},{"left":"27%","top":"68%"},{"left":"66%","top":"80%"}],
"Tomato": [{"left":"30%","top":"32%"},{"left":"69%","top":"33%"},{"left":"27%","top":"72%"},{"left":"66%","top":"80%"}],
"Tree": [{"left":"57%","top":"21%"},{"left":"30%","top":"36%"},{"left":"33%","top":"60%"},{"left":"52%","top":"80%"}],
"Triangle": [{"left":"50%","top":"22%"},{"left":"37%","top":"50%"},{"left":"63%","top":"50%"},{"left":"50%","top":"80%"}],
"Truck": [{"left":"30%","top":"21%"},{"left":"60%","top":"36%"},{"left":"38%","top":"62%"},{"left":"66%","top":"80%"}],
"Wallet": [{"left":"50%","top":"25%"},{"left":"70%","top":"33%"},{"left":"26%","top":"60%"},{"left":"64%","top":"71%"}],
"Want": [{"left":"28%","top":"32%"},{"left":"72%","top":"32%"},{"left":"32%","top":"69%"},{"left":"68%","top":"72%"}],
"Water": [{"left":"40%","top":"24%"},{"left":"73%","top":"30%"},{"left":"24%","top":"68%"},{"left":"60%","top":"70%"}],
"Watermelon": [{"left":"57%","top":"26%"},{"left":"36%","top":"42%"},{"left":"40%","top":"68%"},{"left":"66%","top":"75%"}],
"Wheel": [{"left":"30%","top":"25%"},{"left":"68%","top":"24%"},{"left":"30%","top":"71%"},{"left":"69%","top":"80%"}],
};
export default baseImageLocs;
