import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"; // Import Link and useParams for navigation and URL parameters
import './css/Play.css';
import './css/Sitemap.css';
import images from './components/importImages';
import subimages from './components/importSubImages';
import flagMappings from './components/importFlags';

const ITEMS_PER_PAGE = 500;

const Sitemap = () => {
  const [routes, setRoutes] = useState([]);
  const { page } = useParams(); // Access the 'page' parameter from the URL

  const generateRoutes = () => {
    const generatedRoutes = [];
    const languages = Object.keys(flagMappings);

    Object.keys(images).forEach(baseImg => {
      for (let i = 0; i < languages.length; i++) {
        const language1 = languages[i];

        const trans_word1 = subimages[language1][baseImg]['translation'];
        const route = `/Play/${baseImg}/${language1}/${trans_word1}`;
        generatedRoutes.push(route);
      }
      for (let i = 0; i < languages.length - 1; i++) {
        const language1 = languages[i];

        for (let j = i + 1; j < languages.length; j++) {
          const language2 = languages[j];

          const trans_word1 = subimages[language1][baseImg]['translation'];
          const trans_word2 = subimages[language2][baseImg]['translation'];
          const route = `/Play/${baseImg}/${language1}-${language2}/${trans_word1}-${trans_word2}`;
          generatedRoutes.push(route);
        }
      }
      for (let i = 0; i < languages.length - 2; i++) {
        const language1 = languages[i];
        for (let j = i + 1; j < languages.length - 1; j++) {
          const language2 = languages[j];
          for (let k = j + 1; k < languages.length; k++) {
            const language3 = languages[k];
            const trans_word1 = subimages[language1][baseImg]['translation'];
            const trans_word2 = subimages[language2][baseImg]['translation'];
            const trans_word3 = subimages[language3][baseImg]['translation'];
            const route = `/Play/${baseImg}/${language1}-${language2}-${language3}/${trans_word1}-${trans_word2}-${trans_word3}`;
            generatedRoutes.push(route);
          }
        }
      }
      for (let i = 0; i < languages.length - 3; i++) {
        const language1 = languages[i];

        for (let j = i + 1; j < languages.length - 2; j++) {
          const language2 = languages[j];

          for (let k = j + 1; k < languages.length - 1; k++) {
            const language3 = languages[k];

            for (let l = k + 1; l < languages.length; l++) {
              const language4 = languages[l];
              const trans_word1 = subimages[language1][baseImg]['translation'];
              const trans_word2 = subimages[language2][baseImg]['translation'];
              const trans_word3 = subimages[language3][baseImg]['translation'];
              const trans_word4 = subimages[language4][baseImg]['translation'];
              const route = `/Play/${baseImg}/${language1}-${language2}-${language3}-${language4}/${trans_word1}-${trans_word2}-${trans_word3}-${trans_word4}`;
              generatedRoutes.push(route);
            }
          }
        }
      }
    });

    setRoutes(generatedRoutes);
  };

  useEffect(() => {
    generateRoutes();
  }, []); // Run once on component mount

  // If no page number is specified, generate links for all available pages
  if (!page) {
    const totalPages = Math.ceil(routes.length / ITEMS_PER_PAGE);

    return (
      <div className="centered-container">
        <h1>Sitemap</h1>
        <div className="pagination-links">
          {Array.from({ length: totalPages }).map((_, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span className="separator"> | </span>}
              <Link to={`/sitemap/${index + 1}`}>{index + 1}</Link>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  // Calculate the start and end indices based on the current page
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = page * ITEMS_PER_PAGE;

  // Slice the routes array to display only the routes for the current page
  const currentPageRoutes = routes.slice(startIndex, endIndex);

  return (
    <div className="centered-container">
      <h1>Sitemap - Page {page}</h1>
      <ul className="sitemap-list">
        {currentPageRoutes.map((route, index) => (
          <li key={index}>
            <a href={route}>{route}</a>
          </li>
        ))}
      </ul>
      <div className="pagination-links">
        {Array.from({ length: Math.ceil(routes.length / ITEMS_PER_PAGE) }).map((_, index) => (
          <React.Fragment key={index}>
            {index + 1 === parseInt(page) ? (
              <b>{index + 1}</b>
            ) : (
              <Link to={`/sitemap/${index + 1}`}>{index + 1}</Link>
            )}
            {index < Math.ceil(routes.length / ITEMS_PER_PAGE) - 1 && <span className="separator"> | </span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Sitemap;

