import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './css/index.css';
import Homepage from './Homepage';
import Header from './components/Header';
import Auth from './Auth';
import Play from './Play';
import Donate from './Donate';
import Debug from './Debug';
import About from './About';
import Affiliate from './Affiliate';
import Sitemap from './Sitemap';
import Logout from './Logout';
import Admin from './Admin';
import { UserAuthContextProvider } from "./components/UserAuthContext";

const disableDrag = (event) => {
  event.preventDefault();
};

document.addEventListener('dragstart', disableDrag);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Router>
    <UserAuthContextProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Auth" element={<Auth />} />
        <Route path="/Play" element={<Play />} />
        <Route path="/Play/:word/:languages/:translations" element={<Play />} />
        <Route path="/Donate" element={<Donate />} />
        <Route path="/Debug" element={<Debug />} />
        <Route path="/Debug/:word/:languages/:translations" element={<Debug />} />
        <Route path="/About" element={<About />} />
        <Route path="/Affiliate" element={<Affiliate />} />
        <Route path="/Sitemap" element={<Sitemap />} />
        <Route path="/Sitemap/:page" element={<Sitemap />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="/Ad" element={<Admin />} />
      </Routes>
    </UserAuthContextProvider>
  </Router>

  </React.StrictMode>
);

if (module.hot) {
  module.hot.dispose(() => {
    document.removeEventListener('dragstart', disableDrag);
  });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//import reportWebVitals from './reportWebVitals';
//reportWebVitals();
