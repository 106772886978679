import React from 'react';

function OverlayImage({ position, index }) {
  return (
    <img
      key={index}
      src={position.imageSrc}
      className="overlay-image"
      style={{
        top: position.top,
        left: position.left,
        width: position.width,
        height: position.height,
        //border: '1px solid #555', // remove if not needed
        //color: 'red', // remove if not needed
        //backgroundColor: 'black', // remove if not needed
      }}
      alt={`The word ${position.original} in ${position.language} is ${position.word}`}
    />
  );
}

export default OverlayImage;

