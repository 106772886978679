import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useUserAuth } from "./components/UserAuthContext";
import './css/Donate.css';

const Donate = () => {
  document.title = 'Visulang: Support Our Mission';

  const [showModal, setShowModal] = useState(false);
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [donateURL, setDonateURL] = useState(null);
  const [fromPlay, setFromPlay] = useState(null);

  const { updateUserFromProfile, updateTable, authLoaded, user } = useUserAuth();
  let navigate = useNavigate();

  const loadStripe = () => {
    if (!stripeLoaded) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/buy-button.js';
      script.async = true;
      script.onload = () => setStripeLoaded(true);
      document.body.appendChild(script);
    }
  };

  const injectStripeButton = (uuid, email) => {
    const buttonContainer = document.getElementById('stripe-button-container');
    if (buttonContainer && !document.getElementById("stripeID")) {
      const stripeButton = document.createElement('stripe-buy-button');
      stripeButton.id = "stripeID";
      stripeButton.setAttribute('buy-button-id', 'buy_btn_1PudqMCwpp1h5v6vayVaXbMw');
      stripeButton.setAttribute('publishable-key', 'pk_test_51NdV9kCwpp1h5v6vIdiB4Z3b850n6A7cQ22Dji3fx75J5bcw1MWM9QMXeJoTa5C2AxMKoMLneuc3awKHNWQOBsCH00XBFIAmN5');
      stripeButton.setAttribute('client-reference-id', uuid);
      stripeButton.setAttribute('customer-email', email);
      buttonContainer.appendChild(stripeButton);
    }
  };

  const handleDonateClick = () => {
    setShowModal(true);
  };

  const handleSkip = async (e) => {
    e.preventDefault();
    if (fromPlay === 'true') {
      try {
        updateTable('profiles', {skippedPaid: true}, {id: user.id});
        user.skippedPaid = true;
        navigate(donateURL);
      } catch (error) {
        alert(error.message);
        navigate("/");
      }
    }
  };

  const handleAuthClick = (e) => {
    e.preventDefault();
    if (fromPlay !== null) {
      sessionStorage.setItem('fromPlay', true);
    }
    if (donateURL !== null) {
      sessionStorage.setItem('donateURL', donateURL);
    }

    navigate("/Auth");
    return;
  };

  useEffect(() => {
    if (sessionStorage.getItem('donateURL') !== null) {
      setDonateURL(sessionStorage.getItem('donateURL'));
      sessionStorage.removeItem('donateURL');
    }

    if (sessionStorage.getItem('fromPlay') !== null) {
      const playCount = parseInt(sessionStorage.getItem('playCount')) || 0;
      const newPlayCount = playCount + 1;
      sessionStorage.setItem('playCount', newPlayCount);
      setFromPlay(sessionStorage.getItem('fromPlay'));
      sessionStorage.removeItem('fromPlay');
    }
    loadStripe();
  }, []);

  useEffect(() => {
    if (stripeLoaded && user && showModal) {
      injectStripeButton(user.id, user.email);
    }

    if (user && user.paymentReceived) {
      if (fromPlay === 'true') {
        navigate(donateURL);
      } else {
        navigate("/Play");
      }
    }
  }, [stripeLoaded, user, showModal]);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible' && showModal) {
        try {
          await updateUserFromProfile();
        } catch (error) {
          alert(error.message);
          window.location.href = "/";
          return;
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [showModal]);

  return (
    <div className="app">
      <div className="fade-in-box">
        <div className="sub-box-container">
          {!showModal ? (
            stripeLoaded && (
              !user && authLoaded ? (
                <div className="donate-image-container">
                  <img src="/register.png" alt="Support Visulang by joining our premium program" className="donate-image" />
                </div>
              ) : (
                <div className="donate-image-container">
                  <img src="/donate.png" alt="Support Visulang by joining our premium program" className="donate-image" />
                </div>
              )
            )
          ) : (
            <div className="modal-content">
              <button className="close-button" onClick={() => setShowModal(false)}>X</button>
              <div id="stripe-button-container" className="stripe-box"></div>
            </div>
          )}
        </div>
        <div className="donate-buttons">
          {fromPlay !== null && donateURL !== null && user && !user.skippedPaid && (
            <Link onClick={handleSkip} className='button-container-donate'>Skip</Link>
          )}
          {!showModal ? (
            user ? (
              <Link onClick={handleDonateClick} className='button-container-donate'>Pay</Link>
            ) : authLoaded && (
              <Link onClick={handleAuthClick} className='button-container-donate'>Sign Up</Link>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Donate;
