import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import flagMappings from './components/importFlags';
import preloadImages from './components/preloadImages.js';
import showInstruction from './components/Homepage.js';
import { useUserAuth } from "./components/UserAuthContext";

import './css/Homepage.css';

const Homepage = () => {
  document.title = 'Visulang: Word Learning Language Selection';
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const maxSelectedLanguages = 4;
  const [showInstructionImages, setShowInstructionImages] = useState(0);
  const rows = Math.ceil(Object.keys(flagMappings).length / 2);

  const { authLoaded, user, updateTable } = useUserAuth();
  const navigate = useNavigate();

  const handleLanguageSelect = (event) => {
    const subBox = event.target;
    const language = subBox.getAttribute('data-language');

    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
    } else if (selectedLanguages.length < maxSelectedLanguages) {
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  const handleNext = () => {
    setShowInstructionImages(Math.min(showInstructionImages + 1, 4));
  };

  const storeSelectedLanguages = async () => {
    if (user) {
      try {
        await updateTable('profiles', { selectedLanguages: JSON.stringify(selectedLanguages)}, { id: user.id });
        user.selectedLanguages = JSON.stringify(selectedLanguages);
      } catch (error) {
        alert(error.message);
        localStorage.setItem('selectedLanguages', JSON.stringify(selectedLanguages));
      }
    } else if (authLoaded) {
      localStorage.setItem('selectedLanguages', JSON.stringify(selectedLanguages));
    }
  };

  const handleBeginClick = async (event) => {
    event.preventDefault();
    await storeSelectedLanguages();
    navigate('/Play');
  };

  useEffect(() => {
    const instructionImages = [
      "/instructions1.png",
      "/instructions2.png",
      "/instructions3.png",
      "/donate.png"
    ];
    preloadImages(instructionImages);

    if (user && user.selectedLanguages) {
      navigate('/Play');
      return;
    } else if(!user && authLoaded && localStorage.getItem('selectedLanguages')) {
      navigate('/Play');
      return;
    }
  }, []);

  useEffect(() => {
    if (user && user.selectedLanguages) {
      navigate('/Play');
      return;
    } else if(!user && authLoaded && localStorage.getItem('selectedLanguages')) {
      navigate('/Play');
      return;
    }
  }, [user, authLoaded]);

  useEffect(() => {
    if (selectedLanguages.length > 0 && selectedLanguages.length <= maxSelectedLanguages) {
      setShowInstructionImages(1);
    } else {
      setShowInstructionImages(0);
    }
  }, [selectedLanguages]);

  return (
    <div className="app">
      <div className="fade-in-box">
        <div className="sub-box-container">
          {showInstructionImages > 1 ? (
            showInstruction(showInstructionImages)
          ) : (
            [...Array(rows)].map((_, rowIndex) => (
              <div key={rowIndex} className="sub-box-row">
                {[...Array(2)].map((_, colIndex) => {
                  const index = rowIndex * 2 + colIndex;
                  const language = Object.keys(flagMappings)[index];
                  const languageData = flagMappings[language];
                  const isSelected = selectedLanguages.includes(language);

                  return (
                    <div key={colIndex} className={`sub-box ${isSelected ? 'active' : ''}`} onClick={handleLanguageSelect} data-language={language}>
                      <div className="click-through">
                        <img className="language-select-image" src={languageData['image']} alt={`Learn words in ${language} with Visulang.`} />
                        <div className="country-name no-select" style={{ color: languageData.color }}>
                          {language}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))
          )}
        </div>
        {showInstructionImages === 4 && (
          <Link onClick={handleBeginClick} className="button-container-homepage">Begin!</Link>
        )}
        {showInstructionImages < 4 && showInstructionImages > 0 && (
          <Link onClick={handleNext} className='button-container-homepage'>Continue</Link>
        )}
        {showInstructionImages === 0 && (
          <div className={`instructions ${showInstructionImages > 0 ? 'hidden' : ''}`}>Select up to four languages</div>
        )}
      </div>
    </div>
  );
};

export default Homepage;
