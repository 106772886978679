import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import './css/Play.css';
import images from './components/importImages';
import subImages from './components/importSubImages';
import flagMappings from './components/importFlags';


import OverlayImage from './components/OverlayImage';
import OverlayText from './components/OverlayText';

import subWordLocs from './constants/SubWordLocs';
import baseImageLocs from './constants/BaseImageLocs';

// DEBUG BEGIN
const calculatePercentages = (element, clientX, clientY) => {
  const { top, left, height, width } = element.getBoundingClientRect();
  const topPercentage = ((clientY - top) / height * 100).toFixed(2);
  const leftPercentage = ((clientX - left) / width * 100).toFixed(2);
  return { topPercentage, leftPercentage };
};
//DEBUG END

const bail = () => {
  window.location.href = '/';
  return;
}

const checkSelectedLanguages = (selectedLanguages) => {
  if(selectedLanguages === null || selectedLanguages.length === 0 || selectedLanguages === '[]') {
    const localSelectedLanguages = localStorage.getItem('selectedLanguages');
    if (localSelectedLanguages === null || localSelectedLanguages.length === 0 || localSelectedLanguages === '[]') {
      return [];
    } else {
      return JSON.parse(localSelectedLanguages);
    }
  } else {
    return selectedLanguages;
  }
};

const setTitleWithLanguages = (backgroundImageWord, selectedLanguages) => {
  var documentTitle = "Visulang: " + backgroundImageWord + " in ";
  if (selectedLanguages.length === 1) {
      documentTitle += selectedLanguages[0] + ".";
  } else if (selectedLanguages.length === 2) {
      documentTitle += selectedLanguages[0] + " and " + selectedLanguages[1] + ".";
  } else if (selectedLanguages.length > 2) {
      documentTitle += selectedLanguages.slice(0, -1).join(", ") + ", and " + selectedLanguages[selectedLanguages.length - 1] + ".";
  }

  if(document.title !== documentTitle)
    document.title = documentTitle;
};

/*
play-form-container and main-second-frame:

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const handlePageClick = (event) => {
    if (popupVisible) return;

    const mainFrame = document.getElementById('mainFrame');
    if (!mainFrame) return;

    const { clientX, clientY } = event;
    const { topPercentage, leftPercentage } = calculatePercentages(mainFrame, clientX, clientY);

    setPopupContent(`Top Percentage: ${topPercentage}%\nLeft Percentage: ${leftPercentage}%`);
    setPopupVisible(true);
  };


    <div className="play-form-container" onClick={handlePageClick}>
      <div className="play-form">
        <div className="play-form-content">
        {popupVisible && (
          <div className="popup">
            <pre>{popupContent}</pre>
            <button onClick={() => setPopupVisible(false)}>Close</button>
          </div>
        )}



*/
const Debug = () => {
  const navigate = useNavigate();
  const {word, languages, translations} = useParams(); // /:word/:languages/:translations

  const baseImagePaths = Object.values(images);
  const imageNames = Object.keys(images);

  const [hasInitialized, setHasInitialized] = useState(false);

  const [imageIndex, setImageIndex] = useState(0);
  const [overlayImagePositions, setOverlayImagePositions] = useState([]);
  const [overlayTextPositions, setOverlayTextPositions] = useState([]);
  const [loading, setLoading] = useState(true);

  const backgroundImage = baseImagePaths[imageIndex];
  const backgroundImageWord = imageNames[imageIndex]; // The English word.
  const [selectedLanguages, setSelectedLanguages] = useState(checkSelectedLanguages([])); // The array of languages.

  const translatedWords = selectedLanguages.map((language) => { // An array of translated words.
  // Check if subImages[language] and subImages[language][backgroundImageWord] exist
    if (
      subImages[language] &&
      subImages[language][backgroundImageWord] &&
      subImages[language][backgroundImageWord]['translation']
    ) {
      return subImages[language][backgroundImageWord]['translation'];
    } else {
      // Return a default value or handle the case when the array or property doesn't exist
      return 'null'; // Change this to your desired default value
    }
  });

  //DEBUG BEGIN
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const handlePageClick = (event) => {
//    return;
    if (popupVisible) return;

    const mainFrame = document.getElementById('mainFrame');
    if (!mainFrame) return;

    const { clientX, clientY } = event;
    const { topPercentage, leftPercentage } = calculatePercentages(mainFrame, clientX, clientY);

    setPopupContent(`Top Percentage: ${topPercentage}%\nLeft Percentage: ${leftPercentage}%`);
    setPopupVisible(true);
  };
  //DEBUG END

  const handleNext = () => {
    setLoading(true);
    setImageIndex((prevIndex) => Math.min(prevIndex + 1, baseImagePaths.length - 1));
  };

  const handlePrevious = () => {
    setLoading(true);
    setImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  useEffect(() => {
    const urlLanguage = selectedLanguages.join('-');
    const urlTranslations = translatedWords.join('-');
    const targetURL = `/Debug/${backgroundImageWord}/${urlLanguage}/${urlTranslations}`;

    if (urlLanguage.length === 0 || urlTranslations.length === 0)
      return;

    for (let i = 0; i < selectedLanguages.length; i++) {
      const language = selectedLanguages[i];

      // Check if subImages[language] and subImages[language][backgroundImageWord] exist
      if (
        !subImages[language] ||
        !subImages[language][backgroundImageWord] ||
        subImages[language][backgroundImageWord]['translation'] !== translatedWords[i]
      ) {
        return;
      }
    }

    if (decodeURI(window.location.pathname) !== targetURL) {
      navigate(targetURL);
      setTitleWithLanguages (backgroundImageWord, selectedLanguages);
      return;
    } else {
      setTitleWithLanguages (backgroundImageWord, selectedLanguages);
    }
  }, [backgroundImageWord, selectedLanguages, navigate, translatedWords]);


  if(selectedLanguages.length < 1 && (languages == null || !languages))
    bail();

  useEffect(() => {
    if(languages && translations) {
      if(hasInitialized) {
        return;
      }
    } else {
      setHasInitialized(true);
      return;
    }

    const index = imageNames.indexOf(word);

    if (index === -1) {
      bail();
      return;
    }

    const urlLanguages = languages.split('-');
    const urlTranslations = translations.split('-');

    if (urlLanguages.length < 1 || urlLanguages.length !== urlTranslations.length) {
      bail();
      return;
    }

    for (let i = 0; i < urlLanguages.length; i++) {
      const language = urlLanguages[i];
      if (!(language in flagMappings)) {
        bail();
        return;
      }
      if (subImages[language][word]['translation'] !== urlTranslations[i]) {
        bail();
        return;
      }
    }
    setSelectedLanguages(urlLanguages);
    setImageIndex(index);
    setHasInitialized(true);
  }, [hasInitialized, imageNames, languages, translations, word]);

  useEffect(() => {
    const updateOverlayImagePositions = () => {
      if (selectedLanguages.length > 0) {
        const newOverlayImagePositions = selectedLanguages.map((language, index) => {
          const subImage = subImages[language] && subImages[language][backgroundImageWord];
          if (subImage && baseImageLocs[backgroundImageWord]) {
            return {
              top: baseImageLocs[backgroundImageWord][index]['top'],
              left: baseImageLocs[backgroundImageWord][index]['left'],
              imageSrc: subImage['image'],
              height: '18%',
              word: subImage['translation'],
              language: language,
              original: backgroundImageWord,
            };
          } else {
            return {
            };
          }
        });
        setOverlayImagePositions(newOverlayImagePositions);
      } else {
        setOverlayImagePositions([]);
      }
    };

    const updateOverlayTextPositions = () => {
      if (selectedLanguages.length > 0) {
        const newOverlayTextPositions = selectedLanguages.map((language, index) => {
          const subImage = subImages[language] && subImages[language][backgroundImageWord];
          if (subImage && subWordLocs[backgroundImageWord]) {
            return {
              top: subWordLocs[backgroundImageWord][index]['top'],
              left: subWordLocs[backgroundImageWord][index]['left'],
              word: subImage['translation'],
              flag: flagMappings[language]['image'],
              color: flagMappings[language]['color'],
              language: language,
              original: backgroundImageWord,
            };
          } else {
            return {
            };
          }
        });
        setOverlayTextPositions(newOverlayTextPositions);
      } else {
        setOverlayTextPositions([]);
      }
    };

    updateOverlayImagePositions();
    updateOverlayTextPositions();
  }, [backgroundImageWord, selectedLanguages]);

  useEffect(() => {
    const preloadFiveImages = () => {
      const totalImages = 5;

      for (let i = imageIndex; i < imageIndex + totalImages && i < baseImagePaths.length; i++) {
        const img = new Image();
        img.src = baseImagePaths[i];

        selectedLanguages.forEach((language, index) => {
          if (subImages[language] && subImages[language][imageNames[i]]) {
            const img_j = new Image();
            img_j.src = subImages[language][imageNames[i]]['image'];
          }
        });
      }
    };

    const loadCurrentImage = () => {
      let imagesToLoad = 1;

      const checkLoading = () => {
        imagesToLoad--;
        if (imagesToLoad === 0) {
          setLoading(false);
        }
      };

      const img = new Image();
      img.src = baseImagePaths[imageIndex];
      img.onload = checkLoading;

      selectedLanguages.forEach((language, index) => {
        if (subImages[language] && subImages[language][imageNames[imageIndex]]) {
          imagesToLoad++;
          const img_j = new Image();
          img_j.src = subImages[language][imageNames[imageIndex]]['image'];
          img_j.onload = checkLoading;
        }
      });
    };

    loadCurrentImage();
    preloadFiveImages();
  }, [imageIndex, baseImagePaths, imageNames, selectedLanguages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'ArrowLeft') {
        handlePrevious();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, );

  return (
   <div>
    <div className="play-form-container" onClick={handlePageClick}>
      <div className="play-form">
        <div className="play-form-content">
        {popupVisible && (
          <div className="popup">
            <pre>{popupContent}</pre>
            <button onClick={() => setPopupVisible(false)}>Close</button>
          </div>
        )}
          <div id="mainFrame" className="main-frame">
            {loading ? null : (
              <>
                <div id="mainSecondFrame" className="main-second-frame">
                  <div id="mainThirdFrame" className="main-third-frame" style={{ backgroundImage: `url(${backgroundImage})` }} />
                </div>
                <div className="image-description"> ({backgroundImageWord}) </div>
                {overlayTextPositions.map((position, index) => (
                  <OverlayText key={index} position={position} index={index} />
                ))}
                {overlayImagePositions.map((position, index) => (
                  <OverlayImage key={index} position={position} index={index} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="button-container">
      <div className="bottom-clickable-div left" onClick={handlePrevious}><div className="bottom-click-div left"></div></div>
      <div className="bottom-clickable-div right" onClick={handleNext}><div className="bottom-click-div right"></div></div>
    </div>
  </div>


  );
};

export default Debug;

