// importImages.js
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function importAll(r) {
  let images = {};
  r.keys().forEach((key) => {
    // Extract the image name without the .png extension
    const imageName = capitalizeFirstLetter(key.replace(/^\.\//, '').replace(/flag\.(png)$/, ''));
    images[imageName] = r(key);
  });
  return images;
}


const images = importAll(require.context('../flagimages/', false, /\.(png)$/));

const flagMappings = {
  'Spanish': {'color': '#AD1519', 'image': images['Spanish']},
  'German': {'color': '#000000', 'image': images['German']},
  'French': {'color': '#002654', 'image': images['French']},
  'Polish': {'color': '#DC143C', 'image': images['Polish']},
//  'Croatian': {'color': '#71C5E8', 'image': images['Croatian']},
//  'Slovak': {'color': '#034DA3', 'image': images['Slovak']},
//  'Hindi': {'color': '#046A38', 'image': images['Hindi']},
  'Italian': {'color': '#008C45', 'image': images['Italian']},
  'Portuguese': {'color': '#DA291C', 'image': images['Portuguese']},
//  'Swedish': {'color': '#006AA7', 'image': images['Swedish']},
//  'Hungarian': {'color': '#477050', 'image': images['Hungarian']},
// 'Dutch': {'color': '#003DA5', 'image': images['Dutch']}
};

export default flagMappings;
